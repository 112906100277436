// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Divider, Stack, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  Dineintotal: PropTypes.number.isRequired,
  Takeawaytotal: PropTypes.number.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({
  title,
  Dineintitle,
  Takeaway,
  Dineintotal,
  Takeawaytotal,
  icon,
  color = 'primary',
  sx,
  ...other
}) {
  return (
    <Card
      sx={{
        p: '3px',
        boxShadow: 0,
        textAlign: 'center',
        // color: (theme) => theme.palette[color].darker,
        // bgcolor: (theme) => theme.palette[color].lighter,
        bgcolor: '#000',
        color: '#b68e1d',
        ...sx,
      }}
      {...other}
    >
      {/* <StyledIcon
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
              theme.palette[color].dark,
              0.24
            )} 100%)`,
        }}
      >
        <Iconify icon={icon} width={24} height={24} />
        
      </StyledIcon> */}
      <Stack direction="row" justifyContent="center" alignItems="center">
        <Typography variant="subtitle2" sx={{ opacity: 1 }}>
          {title}
        </Typography>
        {/* <Typography variant="h6">{total}</Typography> */}
      </Stack>

      {/* <Divider /> */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="title2" sx={{ opacity: 1 }}>
          {Dineintitle}
        </Typography>
        <Typography variant="h6">{Dineintotal}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="title3" sx={{ opacity: 1 }}>
          {Takeaway}
        </Typography>
        <Typography variant="h6">{Takeawaytotal}</Typography>
      </Stack>
    </Card>
  );
}
