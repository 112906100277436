import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import { apiSlice } from '../api/apiSlice';

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  user: userReducer,
});

export default rootReducer;
