import { Button, Grid } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateRangePickerComponent = ({ startDate, endDate, handleStartDateChange, handleEndDateChange, onSearch }) => (
  <Grid container spacing={1}>
    <Grid item md={5}>
      <DatePicker selected={startDate} onChange={handleStartDateChange} placeholderText="Start Date" />
    </Grid>
    <Grid item md={5}>
      <DatePicker selected={endDate} onChange={handleEndDateChange} placeholderText="End Date" />
    </Grid>
    <Grid item md={2}>
      <Button variant="outlined" onClick={onSearch}>
        Search
      </Button>
    </Grid>
  </Grid>
);

export default DateRangePickerComponent;
