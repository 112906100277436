import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { doc, getDoc, getDocs } from 'firebase/firestore';
import { Stack } from '@mui/material';
import { db } from '../../services/firebase';

// import { useEffect } from 'react';
// import { useState } from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name, subCategory, theme) {
  return {
    fontWeight:
      subCategory.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}

export default function FilterCategory({
  categories,
  setCategory,
  category,
  subCategory,
  setSubCategory,
  setSubCategoryData,
  subCategoryData,
}) {
  const theme = useTheme();
  //   const [personName, setPersonName] = React.useState([]);
  //   const [categoryAndSubcategories, setCategoryAndSubcategories] = React.useState([]);
  //   const [category, setCategory] = React.useState('indian');
  //   const [subCategory, setSubCategory] = React.useState([]);
  //   const [subCategorySelect,setSubCategorySelect]=React.useState('')
  //   const [indian, setIndian] = React.useState([]);
  //   const [arab, setArab] = React.useState([]);
  //   const [drink, setDrink] = React.useState([]);

  console.log('category', categories, category);

  const onCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const onSubCategoryChange = (event) => {
    setSubCategory(event.target.value);
  };

  return (
    <div>
      <Stack direction="row" gap={2}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="demo-multiple-name-label">Category</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            // multiple
            value={category}
            onChange={onCategoryChange}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {categories.map((name) => (
              <MenuItem key={name.id} value={name.id}>
                {name.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="demo-multiple-name-label">SubCategory</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            // multiple
            value={subCategory}
            onChange={onSubCategoryChange}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
          >
            {subCategoryData?.map((name) => (
              <MenuItem key={name.id} value={name.id} style={getStyles(name, subCategory, theme)}>
                {name.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </div>
  );
}
