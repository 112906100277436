import { useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { onAuthStateChanged } from 'firebase/auth';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import TableManagement from './pages/TableManagement';
import History from './pages/History';
import CurrentOrders from './pages/CurrentOrders';
import { login, selectIsAuthenticated } from './store/userSlice';
import { auth } from './services/firebase';
import { getUserById } from './api/auth';
import { errorNotification } from './utils/notifications';

// ----------------------------------------------------------------------

export default function Router() {
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const dispatch = useDispatch();
  const [authChecked, setAuthChecked] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const { uid } = user;
        const { success, data, err, message } = await getUserById(uid);

        if (success) {
          dispatch(login(data));
        } else {
          // console.log('handleLogin: ', err);
          errorNotification(`${err.name} - ${err.code} - ${err.message}`);
        }
      }

      // Set authChecked to true once authentication state is checked
      setAuthChecked(true);
    });

    // Clean up the subscription
    return unsubscribe;
  }, []);

  // Custom route guard for authenticated routes
  const AuthenticatedRoute = ({ element, ...rest }) => (isAuthenticated ? element : <Navigate to="/login" />);

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/current-orders" />, index: true },
        { path: 'current-orders', element: <AuthenticatedRoute element={<CurrentOrders />} /> },
        { path: 'user', element: <AuthenticatedRoute element={<UserPage />} /> },
        { path: 'products', element: <AuthenticatedRoute element={<ProductsPage />} /> },
        { path: 'blog', element: <AuthenticatedRoute element={<BlogPage />} /> },
        { path: 'table-management', element: <AuthenticatedRoute element={<TableManagement />} /> },
        { path: 'order-history', element: <AuthenticatedRoute element={<History />} /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  // Wait until auth state is checked before rendering routes
  if (!authChecked) {
    return <div>Loading...</div>;
  }

  return routes;
}
