import { writeFile, utils, write } from 'xlsx';

const getSingaporeDate = () => {
  const singaporeTimezone = 'Asia/Singapore';
  const currentDateInSingapore = new Date().toLocaleString('en-US', {
    timeZone: singaporeTimezone,
  });
  const singaporeDate = new Date(currentDateInSingapore);
  return singaporeDate;
};

const exportToExcel = (data, columns, fileName = 'data.xlsx') => {
  const flattenedData = data.map((row) => ({
    ...row,
  }));

  const filteredData = flattenedData.map((row) => {
    const filteredRow = {};
    columns.forEach((col) => {
      if (Array.isArray(row[col])) {
        // If the column value is an array, stringify it
        filteredRow[col] = row[col].join(', ');
      } else {
        filteredRow[col] = row[col];
      }
    });
    return filteredRow;
  });
  const ws = utils.json_to_sheet(filteredData);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, 'Sheet1');
  writeFile(wb, fileName);
};

export { getSingaporeDate, exportToExcel };
