import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Typography } from '@mui/material';
import { useGetAllTablesQuery } from '../api/tableSlice';
import TableCard from '../reusable/modal/table-mgt/TableCard';
import BasicModal from '../reusable/modal/Modal';
import classes from './TableManagement.module.css';

const TableManagement = () => {
  const { data } = useGetAllTablesQuery(undefined);

  const [tableList, setTableList] = useState(data || []);
  const [tableCount, setTableCount] = useState(data?.length || 0);

  useEffect(() => {
    if (data) {
      setTableList(data);
      setTableCount(data?.length);
    }
  }, [data]);

  // useEffect(() => {
  //   const fetchTableData = async () => {
  //     // const promises = [];

  //     const q = query(collection(db, 'tables'), where('branch', '==', userDetail.branch));
  //     const querySnapshot = await getDocs(q);
  //     const fetchedItems = [];
  //     querySnapshot.forEach((doc) => {
  //       // console.log(doc.id, ' tableDetails ', doc.data());
  //       const tableDetails = doc.data();
  //       // console.log('capacity', tableDetails.capacity);
  //       fetchedItems.push({
  //         id: doc.id,
  //         capacity: tableDetails.capacity,
  //         curOrderId: tableDetails.currentOrderId,
  //         occupied: tableDetails.isOccupied,
  //         tableName: tableDetails.name,
  //         status: tableDetails.status,
  //         tableNum: tableDetails.tableNo,
  //         qrUrl: tableDetails.url,
  //         userScanned: tableDetails.userScanned,
  //       });

  //       // const ref = Doc(db, 'tables', doc.id);
  //       // const splittedTableDet = doc.id.split('|');
  //       // promises.push(
  //       //   updateDoc(ref, {
  //       //     branch: splittedTableDet[0],
  //       //     tablePlaced: splittedTableDet[1],
  //       //   })
  //       // );
  //     });
  //     // console.log('fetchedItems', fetchedItems);
  //     setTableList(fetchedItems);
  //     // Set the length of the collection
  //     // console.log('docLength', querySnapshot.size);
  //     setTableCount(querySnapshot.size);

  //     // Promise.all(promises, () => {
  //     //   console.log('success');
  //     // });
  //   };
  //   fetchTableData();
  // }, []);

  // const handleTableAdded = () => {
  //   // Call this function when a table is added in the BasicModal submit handler
  //   setTableAdded(!tableAdded); // Toggle the value to trigger a re-render of TableCard
  // };
  const handleTableData = (data) => {
    // console.log('addedData', data);
    setTableList((prevState) => [
      ...prevState,
      {
        id: data.name,
        capacity: data.capacity,
        curOrderId: data.currentOrderId,
        occupied: data.isOccupied,
        tableName: data.name, // Use the 'name' property
        status: data.status,
        tableNum: data.tableNo,
        qrUrl: data.url, // Use the 'url' property
        userScanned: data.userScanned,
      },
    ]);
  };

  return (
    <>
      <Helmet>
        <title> Table Management | Albarakah Fusion </title>
      </Helmet>
      <div className={classes.tbMgtHeader}>
        <Typography variant="h4">Table Management</Typography>
        <BasicModal totalLength={tableCount} addData={handleTableData} />
      </div>
      <div>
        <TableCard data={tableList} setTableList={setTableList} />
      </div>
    </>
  );
};

export default TableManagement;
