import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { Container, Stack, Typography } from '@mui/material';

import { collection, onSnapshot, doc, getDoc } from 'firebase/firestore'; // Import onSnapshot
import FilterCategory from '../reusable/itemMgt/FilterCategory';
import { db } from '../Firebase/config';

import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';

export default function ProductsPage() {
  const [openFilter, setOpenFilter] = useState(false);
  const [items, setItems] = useState([]);
  const [subCategory, setSubCategory] = React.useState('soup');
  const [categoryAndSubcategories, setCategoryAndSubcategories] = React.useState([]);
  const [category, setCategory] = React.useState('indian');
  const [subCategoryData, setSubCategoryData] = React.useState([]);
  const [subCategorySelect, setSubCategorySelect] = React.useState('');
  const [indian, setIndian] = React.useState([]);
  const [arab, setArab] = React.useState([]);
  const [drink, setDrink] = React.useState([]);
  // Initialize an empty array instead of an array with one empty object

  useEffect(() => {
    // Use onSnapshot to listen for real-time updates
    const unsubscribe = onSnapshot(collection(db, 'items'), (querySnapshot) => {
      const updatedItems = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        updatedItems.push({
          id: doc.id,
          name: data.name,
          category: data.category,
          foodType: data.foodType,
          imgUrl: data.imgUrl,
          isAvailable: data.isAvailable,
          itemPrice: data.itemPrice,
          minItemCount: data.minItemCount,
          totalItemCount: data.totalItemCount,
          subCategory: data.subCategory,
          description: data.description,
        });
      });
      setItems(updatedItems);
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []); // Empty dependency array means this effect runs once when the component mounts.

  React.useEffect(() => {
    async function getCategory() {
      const categoryRef = doc(db, 'appMeta', 'settings');
      const docSnap = await getDoc(categoryRef);
      if (docSnap.exists()) {
        console.log('Document data:', docSnap.data());

        setCategoryAndSubcategories(docSnap.data().categories);
      } else {
        console.log('No such document!');
      }
    }

    getCategory();
  }, []);

  React.useEffect(() => {
    const indianMenu = items.filter((item) => item.category.id === 'indian');
    const arabMenu = items.filter((item) => item.category.id === 'arab');
    const drinksAndDesserts = items.filter((item) => item.category.id === 'drinks_and_desserts');
    setIndian(indianMenu);
    setArab(arabMenu);
    setDrink(drinksAndDesserts);
    const temp = categoryAndSubcategories.filter((item) => item.id === category)[0]?.subcategories;
    setSubCategoryData(temp);
  }, [category, categoryAndSubcategories, subCategoryData, items, subCategory]);

  React.useEffect(() => {
    if (category === 'indian') {
      handlesubCategoryState('soup');
    } else if (category === 'arab') {
      handlesubCategoryState('salad');
    } else {
      handlesubCategoryState('special-falooda-and-dessert');
    }
  }, [category]);

  const handlesubCategoryState = (product) => {
    setSubCategory(product);
  };
  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };
  let product;
  return (
    <>
      <Helmet>
        <title> Item Management | Albarakah Fusion </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Item Management
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            {/* <ProductFilterSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            /> */}
            {/* ItemModal is present in ProductSort */}

            <FilterCategory
              categories={categoryAndSubcategories}
              category={category}
              setCategory={setCategory}
              subCategoryData={subCategoryData}
              setSubCategoryData={setSubCategoryData}
              subCategory={subCategory}
              setSubCategory={setSubCategory}
            />
            {/* <FilterCategory  /> */}
            <ProductSort />
          </Stack>
        </Stack>

        {/* Use the fetched items from Firestore */}

        {category === 'indian' ? (
          <ProductList products={indian} subCategory={subCategory} />
        ) : category === 'arab' ? (
          <ProductList products={arab} subCategory={subCategory} />
        ) : (
          <ProductList products={drink} subCategory={subCategory} />
        )}

        {/* <ProductCartWidget /> */}
      </Container>
    </>
  );
}
