import { Fragment, memo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { IconButton, Input, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Paper from '@mui/material/Paper';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../../../Firebase/config';
import { successNotification } from '../../../utils/notifications';
import HoverTableCell from '../../HoverTableCell';

// const invoiceSubtotal = subtotal(rows);
// const invoiceTaxes = TAX_RATE * invoiceSubtotal;
// const invoiceTotal = invoiceTaxes + invoiceSubtotal;

const CurrentOrdersSubTable = ({ order, setShowModal, orders, setOrderedItems }) => {
  // const [tableItem, setTableItem] = React.useState('booked');
  const [discount, setDiscount] = useState(order?.discountPercentage || 0);
  const [discountAmount, setDiscountAmount] = useState(order?.discountAmount || 0);
  const [discountedTotalAmount, setDiscountedTotalAmount] = useState(order?.discountedTotalAmount || 0);

  // const oldOrder = JSON.parse(JSON.stringify(order));
  // console.log('order1', order);
  const handleDiscount = async () => {
    if (window.confirm(`Apply ${discount}% discount which is ${discountAmount} amount?`)) {
      const discountedAmountInTotal = Math.ceil(order?.totalPriceWithTax - discountAmount);
      setDiscountedTotalAmount(discountedAmountInTotal);

      successNotification('Discount Applied!!!');

      const orderRef = doc(db, 'orders', order.id);
      await updateDoc(orderRef, {
        discountPercentage: discount,
        discountAmount,
        discountedTotalAmount: discountedAmountInTotal,
      });

      console.log({ discountPercentage: discount, discountAmount, discountedTotalAmount: discountedAmountInTotal });
    }
  };

  // const handleSubOrderStatus = async (subOrderItem, isDelete = '') => {
  //   // console.log('subOrderItem', subOrderItem, isDelete);

  //   // const oldOrder = { ...order }; // this is shallow copy and throws error, we need to create deep copy
  //   const oldOrder = JSON.parse(JSON.stringify(order));
  //   console.log('oldOrder: ', oldOrder);

  //   const index = oldOrder.orderedItems.findIndex((subOrder) => subOrder.timestamp === subOrderItem.timestamp);

  //   if (isDelete === 'suborder') {
  //     oldOrder.orderedItems.splice(index, 1);
  //     // console.log('after remove: ', oldOrder.orderedItems);
  //     let totalAmount = 0;
  //     let totalQuantity = 0;

  //     oldOrder.orderedItems.forEach((subOrder) => {
  //       subOrder.items.forEach((item) => {
  //         totalQuantity += item.quantity;
  //         totalAmount += item.totalAmount;
  //       });
  //     });

  //     const taxAmount = (totalAmount * 0.07).toFixed(2);
  //     const packagingCharge =
  //       oldOrder.type === 'take-away' || oldOrder.type !== 'dine-in' ? oldOrder.packagingPrice : 0;
  //     const totalAmountWithTax = (totalAmount + taxAmount + packagingCharge).toFixed(2);
  //     const totalAmountWithTaxRoundOff = Math.round(totalAmountWithTax).toFixed(2);
  //     totalAmount = totalAmount.toFixed(2);

  //     try {
  //       const statusChangeRef = doc(db, 'orders', order.id);
  //       const updatedItem = {
  //         orderedItems: [...oldOrder.orderedItems],
  //         taxPrice: +taxAmount,
  //         totalPrice: +totalAmount,
  //         totalPriceWithTax: +totalAmountWithTaxRoundOff,
  //         totalQuantity: +totalQuantity,
  //       };

  //       await updateDoc(statusChangeRef, updatedItem);
  //       successNotification(`Sub-Order deleted for Order-Id: ${order.id}"`);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   } else if (isDelete === '') {
  //     if (subOrderItem.status === 'booked') {
  //       oldOrder.orderedItems[index] = {
  //         ...oldOrder.orderedItems[index],
  //         status: 'preparing',
  //       };
  //     } else if (subOrderItem.status === 'preparing') {
  //       oldOrder.orderedItems[index] = {
  //         ...oldOrder.orderedItems[index],
  //         status: 'completed',
  //       };
  //     }

  //     try {
  //       const statusChangeRef = doc(db, 'orders', order.id);
  //       const updatedItem = {
  //         orderedItems: [...oldOrder.orderedItems],
  //       };
  //       if (order.status === 'booked' && subOrderItem.status === 'booked') {
  //         updatedItem.status = 'preparing';
  //       }
  //       await updateDoc(statusChangeRef, updatedItem);
  //       successNotification(
  //         `Sub-Order Status Updated for Order-Id: ${order.id} as "${oldOrder.orderedItems[index].status}"`
  //       );
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  // };

  // const handleSubOrderStatus = async (subOrderItem, isDelete = '') => {
  //   const oldOrder = JSON.parse(JSON.stringify(order));
  //   const index = oldOrder.orderedItems.findIndex((subOrder) => subOrder.timestamp === subOrderItem.timestamp);

  //   if (isDelete === 'suborder') {
  //     oldOrder.orderedItems.splice(index, 1);
  //   } else if (isDelete === '') {
  //     const statusMapping = { booked: 'preparing', preparing: 'completed' };
  //     oldOrder.orderedItems[index].status = statusMapping[subOrderItem.status];
  //     if (order.status === 'booked' && subOrderItem.status === 'booked') {
  //       oldOrder.status = 'preparing';
  //     }
  //   }

  //   const { orderedItems } = oldOrder;

  //   let updatedItem = {
  //     orderedItems,
  //     // taxPrice: +taxAmount,
  //     // totalPrice: +totalAmount,
  //     // totalPriceWithTax: +totalAmountWithTaxRoundOff,
  //     // totalQuantity: +totalQuantity,
  //   };

  //   if (isDelete === 'suborder') {
  //     const totalQuantity = orderedItems.reduce(
  //       (total, subOrder) => total + subOrder.items.reduce((subtotal, item) => subtotal + item.quantity, 0),
  //       0
  //     );

  //     const totalAmount = orderedItems
  //       .reduce(
  //         (total, subOrder) => total + subOrder.items.reduce((subtotal, item) => subtotal + item.totalAmount, 0),
  //         0
  //       )
  //       .toFixed(2);

  //     const taxAmount = (totalAmount * 0.07).toFixed(2);
  //     const packagingCharge = oldOrder.type === 'take-away' || oldOrder.type !== 'dine-in' ? oldOrder.packagingPrice : 0;
  //     const totalAmountWithTax = (parseFloat(totalAmount) + parseFloat(taxAmount) + parseFloat(packagingCharge)).toFixed(2);
  //     const totalAmountWithTaxRoundOff = Math.round(totalAmountWithTax).toFixed(2);

  //     updatedItem = {
  //       orderedItems,
  //       taxPrice: +taxAmount,
  //       totalPrice: +totalAmount,
  //       totalPriceWithTax: +totalAmountWithTaxRoundOff,
  //       totalQuantity: +totalQuantity,
  //     };
  //   }

  //   try {
  //     const statusChangeRef = doc(db, 'orders', order.id);
  //     await updateDoc(statusChangeRef, updatedItem);
  //     successNotification(
  //       isDelete === 'suborder'
  //         ? `Sub-Order deleted for Order-Id: ${order.id}"`
  //         : `Sub-Order Status Updated for Order-Id: ${order.id} as "${oldOrder.orderedItems[index].status}"`
  //     );
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const handleSubOrderStatus = async (subOrderItem, isDelete = '', value) => {
    // console.log('handleSubOrderStatus: ', isDelete, subOrderItem, value);

    const oldOrder = JSON.parse(JSON.stringify(order));
    const index = oldOrder.orderedItems.findIndex((subOrder) => subOrder.timestamp === subOrderItem.timestamp);

    if (isDelete === 'suborder') {
      if (window.confirm('Are you sure want to delete this sub-order?') === true) {
        oldOrder.orderedItems.splice(index, 1);
      } else {
        return;
      }
    } else if (isDelete === '') {
      const statusMapping = { booked: 'preparing', preparing: 'completed' };
      oldOrder.orderedItems[index].status = statusMapping[subOrderItem.status];
      if (order.status === 'booked' && subOrderItem.status === 'booked') {
        oldOrder.status = 'preparing';
      }
    } else if (isDelete === 'item') {
      // remove that item
      if (oldOrder.orderedItems[index].items.length === 1) {
        oldOrder.orderedItems.splice(index, 1);
      } else {
        const suborderItemIndex = oldOrder.orderedItems[index].items.findIndex(
          (subOrderItem) => subOrderItem.id === value.id
        );
        oldOrder.orderedItems[index].items.splice(suborderItemIndex, 1);
      }

      // console.log('after remove item: ', oldOrder);
    } else if (isDelete === 'item-quantity') {
      // reduce the quantity by 1 for that item alone
      const suborderItemIndex = oldOrder.orderedItems[index].items.findIndex(
        (subOrderItem) => subOrderItem.id === value.id
      );
      // reduce qty
      oldOrder.orderedItems[index].items[suborderItemIndex].quantity -= 1;
      // re-compute totalPrice
      oldOrder.orderedItems[index].items[suborderItemIndex].totalPrice =
        oldOrder.orderedItems[index].items[suborderItemIndex].quantity *
        oldOrder.orderedItems[index].items[suborderItemIndex].perQuantityPrice;

      // console.log('after reduce item-quantity: ', oldOrder);
    }

    const { orderedItems } = oldOrder;
    const totalQuantity = orderedItems.reduce(
      (total, subOrder) => total + subOrder.items.reduce((subtotal, item) => subtotal + item.quantity, 0),
      0
    );

    const totalAmount = orderedItems
      // .reduce((total, subOrder) => total + subOrder.items.reduce((subtotal, item) => subtotal + item.totalPrice, 0), 0)
      .reduce(
        (total, subOrder) =>
          total + subOrder.items.reduce((subtotal, item) => subtotal + item.quantity * item.perQuantityPrice, 0),
        0
      )
      .toFixed(2);

    const taxAmount = (totalAmount * 0.07).toFixed(2);
    const packagingCharge = oldOrder.type === 'take-away' || oldOrder.type !== 'dine-in' ? oldOrder.packagingPrice : 0;
    const totalAmountWithTax = (parseFloat(totalAmount) + parseFloat(taxAmount) + parseFloat(packagingCharge)).toFixed(
      2
    );
    const totalAmountWithTaxRoundOff = Math.round(totalAmountWithTax).toFixed(2);

    const updatedItem = {
      orderedItems,
      taxPrice: +taxAmount,
      totalPrice: +totalAmount,
      totalPriceWithTax: +totalAmountWithTaxRoundOff,
      totalQuantity: +totalQuantity,
    };

    console.log('udpatedItem: ', updatedItem);

    try {
      const statusChangeRef = doc(db, 'orders', order.id);
      await updateDoc(statusChangeRef, updatedItem);
      successNotification(
        isDelete === 'suborder'
          ? `Sub-Order deleted for Order-Id: ${order.id}"`
          : `Sub-Order Status Updated for Order-Id: ${order.id} as "${oldOrder.orderedItems[index].status}"`
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table
        sx={{ minWidth: 830, border: '1px solid black', borderCollapse: 'collapse' }}
        aria-label="spanning table"
        className="subTabel"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }}>Sub-Id</TableCell>
            <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} align="right">
              Timestamp
            </TableCell>
            <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} align="right">
              Name
            </TableCell>
            <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} align="right">
              Qty
            </TableCell>
            <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} align="right">
              Price
            </TableCell>
            <TableCell
              sx={{ border: '1px solid black', borderCollapse: 'collapse' }}
              align="right"
              colSpan={order.type === 'take-away' && 3}
            >
              Total
            </TableCell>
            {order.type === 'dine-in' && (
              <>
                <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} align="right">
                  Status
                </TableCell>

                <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} align="right">
                  Change Status
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {order.orderedItems.map((item, i) => (
            <Fragment key={i}>
              {item &&
                item.items.map((val, idx) => (
                  <>
                    {/* {setTableItem(val)} */}
                    <TableRow key={idx}>
                      {idx === 0 ? (
                        <TableCell
                          sx={{ border: '1px solid black', borderCollapse: 'collapse' }}
                          rowSpan={item.items.length}
                        >{`${i + 1}`}</TableCell>
                      ) : null}
                      <TableCell align="right" sx={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                        {item && item.timestamp
                          ? new Date(item.timestamp).toLocaleString('en-US', { timeZone: 'Asia/Singapore' })
                          : ''}
                      </TableCell>
                      {/* Hover Table Cell */}
                      <HoverTableCell
                        item={item}
                        val={val}
                        i={i}
                        idx={idx}
                        handleSubOrderStatus={handleSubOrderStatus}
                      />
                      {/* Hover Table Cell */}
                      <TableCell align="right" sx={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                        {val && val.quantity ? val.quantity : ''}
                      </TableCell>
                      <TableCell align="right" sx={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                        {val && val.perQuantityPrice ? val.perQuantityPrice : ''}
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{ border: '1px solid black', borderCollapse: 'collapse' }}
                        colSpan={order.type === 'take-away' && 3}
                      >
                        {val && val.totalPrice ? val.totalPrice : ''}
                      </TableCell>
                      {idx === 0 && order.type === 'dine-in' ? (
                        <TableCell
                          align="right"
                          rowSpan={item.items.length}
                          sx={{ border: '1px solid black', borderCollapse: 'collapse' }}
                        >
                          {item && item.status ? item.status : ''}
                        </TableCell>
                      ) : null}
                      {idx === 0 && order.type === 'dine-in' ? (
                        <TableCell
                          align="right"
                          rowSpan={item.items.length}
                          sx={{ border: '1px solid black', borderCollapse: 'collapse' }}
                        >
                          {item.status === 'completed' || item.status === 'cancelled' ? null : (
                            <>
                              <button
                                type="button"
                                onClick={() => {
                                  handleSubOrderStatus(item);
                                }}
                                className="statusBtn"
                              >
                                {item.status === 'booked' ? 'preparing' : 'completed'}
                              </button>

                              {(item.status === 'booked' || item.status === 'preparing') && (
                                <IconButton
                                  aria-label="delete suborder item"
                                  color="inherit"
                                  onClick={() => handleSubOrderStatus(item, 'suborder')}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </>
                          )}
                        </TableCell>
                      ) : null}
                    </TableRow>
                  </>
                ))}
            </Fragment>
          ))}
          <>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} colSpan={2}>
                Sub-Total
              </TableCell>
              {order.type === 'take-away'}
              <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} align="right">
                {order.totalPrice}
              </TableCell>
              {/* {console.log("totalprice",order.orderedItems)} */}
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} colSpan={2}>
                Service Charge
              </TableCell>
              <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} align="right">
                {order?.serviceCharge}
              </TableCell>
              {/* {console.log("totalprice",order.orderedItems)} */}
            </TableRow>
            {order.type === 'take-away' && (
              <TableRow>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} colSpan={2}>
                  packaging Charges
                </TableCell>
                <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} align="right">
                  {order.packagingPrice}
                </TableCell>
                {/* {console.log("totalprice",order.orderedItems)} */}
              </TableRow>
            )}
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }}>Tax</TableCell>
              <TableCell
                sx={{ border: '1px solid black', borderCollapse: 'collapse' }}
                align="right"
              >{`${order.taxPercentage}%`}</TableCell>
              <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} align="right">
                {order.taxPrice}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} colSpan={2}>
                Total (RoundOff)
              </TableCell>
              <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} align="right">
                {order.totalPriceWithTax}
              </TableCell>
            </TableRow>
            {order.status === 'preparing' && (
              <>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }}>
                    Discount %{' '}
                    {order?.discountAmount && order?.discountPercentage && order?.discountedTotalAmount ? null : (
                      <button type="button" className="statusBtn" onClick={handleDiscount}>
                        Apply
                      </button>
                    )}
                  </TableCell>
                  <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} align="right">
                    <Input
                      disabled={order?.discountAmount && order?.discountPercentage && order?.discountedTotalAmount}
                      sx={{ width: '25px', fontSize: '0.8rem', padding: '2px' }}
                      value={discount}
                      onChange={(e) => {
                        setDiscount(e.target.value);
                        setDiscountAmount((order.totalPriceWithTax * (e.target.value / 100)).toFixed(2));
                      }}
                    />
                    %
                  </TableCell>
                  <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} align="right">
                    {discountAmount}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} colSpan={2}>
                    Discounted Total (RoundOff)
                  </TableCell>
                  <TableCell sx={{ border: '1px solid black', borderCollapse: 'collapse' }} align="right">
                    {discountedTotalAmount}
                  </TableCell>
                </TableRow>
              </>
            )}
          </>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(CurrentOrdersSubTable);
