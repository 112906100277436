import { collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../services/firebase';

const { apiSlice } = require('./apiSlice');

const itemSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getOutOfStockItems: build.query({
      queryFn: async () => ({ data: null }),
      onCacheEntryAdded: async (_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved }) => {
        // _ as 1st param, it requried to get 2nd param, but not using, so kept as _
        let unsubscribe = () => {};
        try {
          await cacheDataLoaded;

          const currentOrdersRef = query(collection(db, 'items'), where('isAvailable', '==', true));

          unsubscribe = onSnapshot(currentOrdersRef, (snapshot) => {
            // console.log('snapshot', snapshot.size);
            const items = [];
            snapshot.docs.forEach((doc) => {
              const data = {
                id: doc.id,
                ...doc.data(),
              };
              // console.log('getOutOfStockItems: ', data);
              if (data.totalItemCount <= data.minItemCount) {
                items.push(data);
              }
            });
            updateCachedData((draft) => items);
          });
        } catch (error) {
          console.log('error in users!', error);
          throw new Error('Something went wrong with users.');
        }
        await cacheEntryRemoved;
        unsubscribe();
      },
    }),
    updateItemStock: build.mutation({
      queryFn: async ({ id, newStockValue }) => {
        // console.log('idddd: ', id, newStockValue);
        try {
          const itemRef = doc(db, 'items', id);

          await updateDoc(itemRef, {
            totalItemCount: newStockValue,
          });

          return {
            data: 'ok',
          };
        } catch (e) {
          console.log('error in stock update!', e);
          return {
            error: e,
          };
        }
      },
    }),
    getItems: build.query({
      queryFn: async (category) => {
        try {
          const itemRef = collection(db, 'items');
          // Create a query against the collection.
          const q = query(itemRef, where('isAvailable', '==', true), where('category.id', '==', category));
          const querySnapshot = await getDocs(q);
          const arr = [];
          querySnapshot.forEach((doc) => {
            arr.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          // console.log("arr", arr);
          return { data: arr };
        } catch (e) {
          console.log(e);
          return {
            error: {
              name: 'getItems',
              message: e?.message || 'error',
              data: e,
            },
          };
        }
      },
    }),
    getItemById: build.query({
      queryFn: async (docId) => {
        try {
          const collection = 'items';
          const docRef = doc(db, collection, docId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            // console.log("Document data:", docSnap.data());
            return {
              data: docSnap.data(),
            };
          }
          // docSnap.data() will be undefined in this case
          return {
            // only returns data, error, meta
            error: {
              name: 'invalid_id',
              message: `No such document - path: ${collection}/${docId}`,
              data: '',
            },
          };
        } catch (e) {
          return {
            error: {
              name: 'getItemById',
              message: e?.message || 'error',
              data: e,
            },
          };
        }
      },
    }),
  }),
});

export const { useGetItemsQuery, useGetItemByIdQuery, useGetOutOfStockItemsQuery, useUpdateItemStockMutation } =
  itemSlice;
