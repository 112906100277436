import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { doc, updateDoc } from 'firebase/firestore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CurrentOrdersSubTable from './CurrentOrdersSubTable';
import { db } from '../../../Firebase/config';
import StatusModal from '../StatusModal';
import { successNotification, warningNotification } from '../../../utils/notifications';

function OrderRow({ order, setOrderedItems, orders }) {
  const [open, setOpen] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  const [showModalTakeAway, setShowModalTakeAway] = React.useState(false);
  // console.log('order for takeaway', order);
  const [order1, setOrder1] = React.useState();

  const handleStatus = async () => {
    const statusRef = doc(db, 'orders', order.id);
    if (order.status === 'booked') {
      if (order.type === 'take-away') {
        setShowModal(true);
        if (showModalTakeAway) {
          const updatedItems = order.orderedItems.map((item) => ({
            ...item,
            status: 'preparing',
          }));
          await updateDoc(statusRef, {
            status: 'preparing',
            orderedItems: [...updatedItems],
          });
        }
      } else {
        const updatedItems = order.orderedItems.map((item) => ({
          ...item,
          status: 'preparing',
        }));
        setOrder1(updatedItems);
        await updateDoc(statusRef, {
          status: 'preparing',
          orderedItems: [...updatedItems],
        });
        setOrderedItems({
          ...order,
          status: 'preparing',
          orderedItems: [...updatedItems],
        });
      }
    } else if (order.status === 'preparing' && order.type === 'dine-in') {
      const index = order.orderedItems.findIndex((item) => item.status === 'booked' || item.status === 'preparing');
      if (index === -1) {
        setShowModal(true);
      } else {
        warningNotification('Complete sub-orders first then complete the order!!!');
      }
    } else if (order.status === 'preparing' && order.type === 'take-away') {
      const updatedItems = order.orderedItems.map((item) => ({
        ...item,
        status: 'completed',
      }));
      await updateDoc(statusRef, {
        status: 'completed',
        orderedItems: [...updatedItems],
      });
      setOrderedItems({
        ...order,
        status: 'completed',
        orderedItems: [...updatedItems],
      });
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    // setShowModalTakeAway(false);
  };

  const tablerow = {
    maxHeight: '300px',
    overflowY: 'scroll',
  };

  const handleStatusTakeAway = () => {
    if (order.status === 'preparing') {
      setShowModal(true);
    }
  };

  const handleCancelOrder = async () => {
    if (window.confirm('Do you want to cancel the order!') === true) {
      const statusRef = doc(db, 'orders', order.id);
      const updatedItems = order.orderedItems.map((item) => ({
        ...item,
        status: 'cancelled',
      }));
      await updateDoc(statusRef, {
        status: 'cancelled',
        orderedItems: [...updatedItems],
      });

      const tableRef = doc(db, 'tables', order.tableNo);
      await updateDoc(tableRef, {
        isLock: false,
        // currentOrderId: '',
        isOccupied: false,
        status: 'free',
      });
      // table, user - currentOrderId remove it in morning
      successNotification('Order Cancelled!!!');
    }
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell sx={{ width: '12%' }} component="th" scope="row">
          {order.orderNo}
        </TableCell>
        <TableCell sx={{ width: '12%' }} align="right">
          {order.tableNo}
        </TableCell>
        <TableCell sx={{ width: '15%' }} align="right">
          {new Date(order.orderedTimestamp).toLocaleString('en-US', { timeZone: 'Asia/Singapore' })}
        </TableCell>
        <TableCell sx={{ width: '15%' }} align="right">
          {order.totalQuantity}
        </TableCell>
        <TableCell sx={{ width: '15%' }} align="right">
          RM {order.totalPriceWithTax}
        </TableCell>
        <TableCell sx={{ width: '12%' }} align="right">
          {order.paymentStatus}
        </TableCell>
        <TableCell sx={{ width: '12%' }} align="right">
          {order.status}
        </TableCell>
        <TableCell sx={{ width: '5%' }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit sx={tablerow}>
            <Box sx={{ margin: 1 }}>
              <div className="subOrderHeading">
                <Typography variant="h6" gutterBottom component="div">
                  SubOrder Details
                </Typography>

                {order.status === 'completed' || order.status === 'cancelled' ? null : (
                  <div className="">
                    <button type="button" className="statusBtn" onClick={() => handleCancelOrder()}>
                      Cancel Order
                    </button>
                    <button type="button" className="statusBtn" onClick={() => handleStatus()}>
                      {order.status === 'booked' ? 'Preparing' : order.status === 'preparing' ? 'completed' : ''}
                    </button>
                  </div>
                )}
              </div>
              {showModal && (
                <StatusModal
                  open={showModal}
                  openModal={handleStatus}
                  closeModal={handleModalClose}
                  docId={order.id}
                  order={order}
                  setShowModalTakeAway={setShowModalTakeAway}
                  setOrderedItems={setOrderedItems}
                />
              )}
              <CurrentOrdersSubTable
                order={order}
                order1={order1}
                setOrderedItems={setOrderedItems}
                setShowModal={(show) => setShowModal(show)}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

OrderRow.propTypes = {
  order: PropTypes.shape({
    orderNo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    tableNo: PropTypes.string.isRequired,
    paymentStatus: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    // history: PropTypes.arrayOf(
    //   PropTypes.shape({
    //     amount: PropTypes.number.isRequired,
    //     customerId: PropTypes.string.isRequired,
    //     date: PropTypes.string.isRequired,
    //   })
    // ).isRequired,
    orderedTimestamp: PropTypes.number.isRequired,
    totalQuantity: PropTypes.number.isRequired,
    totalPriceWithTax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
};

export default function OrdersTable({ orders, order, setOrderedItems }) {
  // console.log('orders', orders, order);
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '10%' }}>Order No</TableCell>
              <TableCell sx={{ width: '12%' }} align="right">
                Table No
              </TableCell>
              <TableCell sx={{ width: '12%' }} align="right">
                Order Time
              </TableCell>
              <TableCell sx={{ width: '12%' }} align="right">
                Total Quantity
              </TableCell>
              <TableCell sx={{ width: '12%' }} align="right">
                Total Price
              </TableCell>
              <TableCell sx={{ width: '12%' }} align="right">
                Payment Status
              </TableCell>
              <TableCell sx={{ width: '12%' }} align="right">
                Status
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {order ? (
              <OrderRow order={order} setOrderedItems={setOrderedItems} />
            ) : (
              <>
                {orders?.map((order) => (
                  <OrderRow key={order.id} order={order} setOrderedItems={setOrderedItems} />
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

OrdersTable.propTypes = {
  orders: PropTypes.array,
};
