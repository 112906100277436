import PropTypes from 'prop-types';
import { set, sub } from 'date-fns';
import { noCase } from 'change-case';
import { faker } from '@faker-js/faker';
import { useEffect, useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
// utils
import { useDispatch } from 'react-redux';
import { notify } from '../../../store/userSlice';
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { useGetTableRequestsQuery, useUpdateTableRequestStatusMutation } from '../../../api/tableSlice';
import { successNotification } from '../../../utils/notifications';

// ----------------------------------------------------------------------

const NOTIFICATIONS = [
  {
    id: faker.datatype.uuid(),
    title: 'Table 1 ',
    description: 'waiting for request',
    avatar: '../../images/dining-table.png',
    type: 'friend_interactive',
    createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
    isUnRead: true,
  },
  {
    id: faker.datatype.uuid(),
    title: 'Table 2 ',
    description: 'waiting for request',
    avatar: '../../images/dining-table.png',
    type: 'friend_interactive',
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true,
  },
  {
    id: faker.datatype.uuid(),
    title: 'Table 3 ',
    description: 'waiting for request',
    avatar: '../../images/dining-table.png',
    type: 'friend_interactive',
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true,
  },

  {
    id: faker.datatype.uuid(),
    title: 'You have new message',
    description: '5 unread messages',
    avatar: null,
    type: 'chat_message',
    createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {
    id: faker.datatype.uuid(),
    title: 'You have new mail',
    description: 'sent from Guido Padberg',
    avatar: null,
    type: 'mail',
    createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {
    id: faker.datatype.uuid(),
    title: 'Delivery processing',
    description: 'Your order is being shipped',
    avatar: null,
    type: 'order_shipped',
    createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
];

export default function NotificationsPopover() {
  const { data } = useGetTableRequestsQuery();
  const dispatch = useDispatch();
  const [updateTableRequestStatusMutation, { isLoading, isError, isSuccess, data: result }] =
    useUpdateTableRequestStatusMutation();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    setNotifications(
      data?.map((table) => ({
        ...table,
        type: 'friend_interactive',
        isUnRead: true,
        avatar: '../../images/dining-table.png',
        title: `Table ${table.tableNo}`,
        description: 'waiting for request',
        // createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
      }))
    );

    if (data?.length > 0) {
      // successNotification(`New Table Requested`, true, true);
      successNotification(`New Table Requested`);
      dispatch(notify(true));
    } else {
      dispatch(notify(false));
    }
  }, [data]);

  // console.log('tableRequests: ', notifications);

  const totalUnRead = notifications?.filter((item) => item.isUnRead === true).length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  const tablerequest = {
    maxHeight: '300px',
    overflowY: 'scroll',
  };

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 400,
          },
        }}
      >
        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
            Table Request
          </ListSubheader>
          <List sx={tablerequest} disablePadding>
            {notifications?.length === 0 ? (
              <p>Empty</p>
            ) : (
              notifications?.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  onStatusUpdate={(id, status) => updateTableRequestStatusMutation({ id, status })}
                  isLoading={isLoading}
                />
              ))
            )}
          </List>
          {/* <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1">Notifications</Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                You have {totalUnRead} unread messages
              </Typography>
            </Box>

            {totalUnRead > 0 && (
              <Tooltip title=" Mark all as read">
                <IconButton color="primary" onClick={handleMarkAllAsRead}>
                  <Iconify icon="eva:done-all-fill" />
                </IconButton>
              </Tooltip>
            )}
          </Box> */}

          {/* <Divider sx={{ borderStyle: 'dashed' }} />
          <List sx={tablerequest} disablePadding>
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List> */}
        </Scrollbar>

        {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification, onStatusUpdate, isLoading }) {
  console.log('notification: ', notification);
  // updateTableRequestStatusMutation(1, 'accepted');
  const { avatar, title } = renderContent(notification);

  const approved = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  };

  return (
    <>
      <Box>
        <ListItemButton
          sx={{
            py: 1,
            px: 1,
            mt: '1px',
            ...(notification.isUnRead && {
              bgcolor: 'action.selected',
            }),
          }}
        >
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={title}
            // secondary={
            //   <Typography
            //     variant="caption"
            //     sx={{
            //       mt: 0.5,
            //       display: 'flex',
            //       alignItems: 'center',
            //       color: 'text.disabled',
            //     }}
            //   >
            //     <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            //     {fToNow(notification.createdAt)}
            //   </Typography>
            // }
          />
          <div style={approved}>
            <IconButton onClick={() => onStatusUpdate(notification.id, 'accepted')} disabled={isLoading}>
              <CheckCircleIcon sx={{ color: 'green' }} />
            </IconButton>
            <IconButton onClick={() => onStatusUpdate(notification.id, 'rejected')} disabled={isLoading}>
              <CancelIcon sx={{ color: 'red' }} />
            </IconButton>
          </div>
        </ListItemButton>
      </Box>
    </>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2" sx={{ color: '#b68e1d' }}>
      {notification.title}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.description)}
      </Typography>
    </Typography>
  );

  if (notification.type === 'order_placed') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_package.svg" />,
      title,
    };
  }
  if (notification.type === 'order_shipped') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_shipping.svg" />,
      title,
    };
  }
  if (notification.type === 'mail') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_mail.svg" />,
      title,
    };
  }
  if (notification.type === 'chat_message') {
    return {
      avatar: <img alt={notification.title} src="/assets/icons/ic_notification_chat.svg" />,
      title,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
