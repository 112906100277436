import { doc, getDoc } from 'firebase/firestore';
import { db } from '../services/firebase';

const getUserById = async (uid) => {
  try {
    const collection = 'admin';
    const docRef = doc(db, collection, uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // console.log('Document data:', docSnap.data());
      const { name, email, branch } = docSnap.data();
      return {
        success: true,
        message: 'Successfully Retrieved',
        data: {
          id: docSnap.id,
          name,
          email,
          branch,
        },
        err: null,
      };
    }
    return {
      success: false,
      message: null,
      data: null,
      err: {
        name: 'invalid_id',
        message: `No such document - path: ${collection}/${uid}`,
        code: '',
        data: '',
      },
    };
  } catch (e) {
    return {
      success: false,
      message: null,
      data: null,
      err: {
        name: 'Firebase Error',
        message: e.message,
        code: e.code,
        data: e,
      },
    };
  }
};

export { getUserById };
