import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { doc, setDoc, collection, onSnapshot } from 'firebase/firestore';
import Modal from '@mui/material/Modal';
import { db } from '../../Firebase/config';
import classes from './Modal.module.css';
import QrCode from './qrcode/QrCode';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: 3,
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ totalLength, addData, ...props }) {
  const [open, setOpen] = React.useState(false);
  const [showQr, setShowQr] = React.useState(false);
  const [tableName, setTableName] = React.useState('');
  const [capacity, setCapacity] = React.useState(4);
  const [showBtn, setShowBtn] = React.useState(false);
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    if (open) {
      setTableName(`b2|o|table-${totalLength + 1}`);
    }
  }, [open, totalLength]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setShowQr(false);
    setShowBtn(false);
  };
  const handleQr = () => {
    setShowQr(true);
    setShowBtn(true);
  };
  const submitHandler = async (event) => {
    event.preventDefault();
    // const nextTableNo = totalLength + 1;
    // console.log('nextTableNo', nextTableNo);
    const tableNo = +tableName.split('-')[1];
    try {
      await setDoc(doc(db, 'tables', `${tableName}`), {
        capacity,
        currentOrderId: '',
        isOccupied: false,
        name: tableName,
        status: 'free',
        tableNo,
        url: `https://albarakahrestaurant.com?table-no=${tableName}&type=dine-in`,
      });
    } catch (err) {
      console.log('err', err);
    }
    addData({
      capacity,
      currentOrderId: '',
      isOccupied: false,
      name: tableName,
      status: 'free',
      tableNo,
      url: `https://albarakahrestaurant.com?table-no=${tableName}&type=dine-in`,
    });
    alert('saved successfully');
    handleClose();
  };

  return (
    <div>
      <Button onClick={handleOpen} className={classes.createBtn}>
        Create Table
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <TextField
            id="outlined-basic"
            label="Table Name"
            variant="outlined"
            value={tableName}
            fullWidth
            onChange={(e) => {
              setTableName(e.target.value);
            }}
            sx={{ mb: 2 }}
          />
          <TextField
            id="outlined-basic"
            label="Capacity"
            variant="outlined"
            type="number"
            value={capacity}
            fullWidth
            onChange={(e) => {
              setCapacity(e.target.value);
            }}
            sx={{ mb: 2 }}
          />
          {!showQr && (
            <Button variant="contained" className={classes.qrBtn} onClick={handleQr} disabled={!tableName || !capacity}>
              Generate QR code
            </Button>
          )}
          {showQr && (
            <div className={classes.sectionqr}>
              <QrCode qrCodeUrl={`https://albarakahrestaurant.com?table-no=${tableName}&type=dine-in`} type="add" />
            </div>
          )}
          <div className={classes.subBtn}>
            <Button variant="contained" className={classes.saveBtn} onClick={submitHandler} disabled={!showBtn}>
              Save
            </Button>
            <Button variant="outlined" className={classes.cancelBtn} onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
