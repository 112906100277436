import { useState } from 'react';
import { Card, Grid, Stack } from '@mui/material';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import classes from './TableCard.module.css';
import ListModal from './ListModal';
import IconGroup from './IconGroup';

const TableCard = ({ data, setTableList }) => {
  // console.log('data: ', data);
  const [open, setOpen] = useState(false);
  const [currentOrderId, setCurrentOrderId] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setCurrentOrderId('');
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 3 }}>
        {data.map((item) => (
          <Grid item md={3} key={item.id}>
            <div>
              <Card className={classes.tableCard}>
                <div className={classes.viewContainer}>
                  {item.occupied ? (
                    <>
                      <div className={`${classes.tableandicon}`}>
                        <p className={`${classes.tableTitle} ${classes.redText}`}>{item.tableName}</p>
                        <div className={`${classes.unlock}`}>
                          <IconGroup item={item} data={data} setTableList={setTableList} />
                        </div>
                      </div>
                      <img src="../images/redtable.png" alt="" style={{ maxWidth: '40%' }} />
                    </>
                  ) : (
                    <>
                      <div className={`${classes.tableandicon}`}>
                        <p className={`${classes.tableTitle} ${classes.greenText}`}>{item.tableName}</p>
                        <div className={`${classes.unlock}`}>
                          <IconGroup item={item} data={data} setTableList={setTableList} />
                        </div>
                      </div>
                      <img src="../images/greentable.png" alt="" style={{ maxWidth: '40%' }} />
                    </>
                  )}
                  {/* <img src="../images/redtable.png" alt="" style={{ maxWidth: '40%' }} /> */}
                  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
                    <p className={classes.tableTitle}>Capacity: {item.capacity}</p>

                    <ReceiptLongIcon
                      onClick={() => {
                        handleOpen();
                        setCurrentOrderId(item.currentOrderId);
                      }}
                      color="#000"
                      sx={{ cursor: 'pointer' }}
                    />
                  </Stack>
                </div>

                {/* <DownloadCode /> */}
              </Card>
            </div>
          </Grid>
        ))}

        {open ? (
          <ListModal open={open} handleOpen={handleOpen} handleClose={handleClose} orderId={currentOrderId} />
        ) : null}
      </Grid>
    </>
  );
};

export default TableCard;
