import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userDetail: {
    id: '',
    name: '',
    email: '',
    branch: '',
  },
  isAuthenticated: false,
  isPlayNotification: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.userDetail = action.payload;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.userDetail = {
        id: '',
        name: '',
        email: '',
        branch: '',
      };
      state.isAuthenticated = false;
    },
    notify: (state, action) => {
      state.isPlayNotification = action.payload
    }
  },
});

export const { login, logout, notify } = userSlice.actions;

export const selectUser = (state) => state.user;
export const selectUserDetail = (state) => state.user.userDetail;
export const selectIsAuthenticated = (state) => state.user.isAuthenticated;
export const selectIsNotify = (state) => state.isPlayNotification

export default userSlice.reducer;
