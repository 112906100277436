import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function ItemCategorySelect({ title, label, value, list, ...props }) {
  // console.log('ItemCategorySelect', value);

  const handleChange = (val) => {
    // console.log('selectValue', val);
    if (label === 'Category') {
      props.setItem(val);
    } else if (label === 'Sub Category') {
      props.handleSubCategory(val);
    } else {
      props.setType(val);
    }
  };

  return (
    <Box sx={{ minWidth: 100, marginTop: '10px' }}>
      <FormControl sx={{ minWidth: '88%' }}>
        <InputLabel id="demo-simple-select-label">{title}</InputLabel>
        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={value} label={label}>
          {Array.isArray(list) &&
            list.map((cat) => (
              <MenuItem key={cat.id} value={cat.name} onClick={() => handleChange(cat)}>
                {cat.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}
