import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Button, Typography } from '@mui/material';
import OrdersTable from '../reusable/modal/table/OrdersTable';
import classes from './History.module.css';
import DateRangePickerComponent from '../reusable/modal/date-range-picker/DateRangePicker';
import { useLazyGetOrderHistoryQuery } from '../api/orderSlice';

const History = () => {
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);

  const handleStartDateChange = (date) => {
    setStartDate(date.getTime());
  };

  const handleEndDateChange = (date) => {
    // 1. startDate should not be greater than endDate
    const ms = date.getTime();
    if (ms >= startDate) {
      setEndDate(ms);
    }
  };

  // no need to use skipToken now, when we use useLazyQuery. So we are reducing some search state code which is there previously
  // and using this to call server api, each time, when search is clicked
  const [lazyQuery, { data, isLoading, isError, isFetching }] = useLazyGetOrderHistoryQuery(
    {},
    {
      preferCacheValue: true, // Set to true if you want to prefer cached data
    }
  );
  // console.log('history: ', data, isError, isFetching, isLoading);

  const handleSearch = () => {
    // validate date:
    // 1. difference between startDate and endDate should be 1month only
    const newEndDate = new Date(endDate);
    newEndDate.setHours(23, 59, 59, 59);

    const newEndDateMs = newEndDate.getTime();
    // Use lazyQuery to trigger the query on-demand
    lazyQuery({ startDate, endDate: newEndDateMs });
  };

  const exportOrders = () => {
    // console.log('exportOrders: ', startDate, endDate, data);
    const selectedStartDate = new Date(startDate);
    const selectedEndDate = new Date(endDate);

    const formattedData = data.map((order) => {
      let formattedItems = [];

      order.orderedItems.forEach((prod) => {
        formattedItems = [...formattedItems, ...prod.items];
      });

      return {
        'Order No': order.id,
        'Table No': `${order.tableNo}`,
        Type: order.type,
        'Total Quantity': order.totalQuantity,
        'Total Price': `Rs. ${order.totalPrice}`,
        'Tax Percentage': `Rs. ${order.taxPercentage}`,
        'Tax Price': `Rs. ${order.taxPrice}`,
        Status: order.status,
        'Ordered Items': formattedItems
          .map((prod) => `${prod.name} (${prod.quantity} x ${prod.perQuantityPrice}) - Rs.${prod.totalPrice}`)
          .join(' | '),
        'Ordered Date': order.orderedTimestamp,
        'Payment Method': order.paymentMethod,
        'Payment Status': order.paymentStatus,
        'User Name': order.userDetails.name,
        'User Email': order.userDetails.email,
        'User Phone': order.userDetails.phone,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const fileData = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const day = selectedStartDate.getDate().toString().padStart(2, '0');
    const month = (selectedStartDate.getMonth() + 1).toString().padStart(2, '0');
    const year = selectedStartDate.getFullYear().toString();

    const eday = selectedEndDate.getDate().toString().padStart(2, '0');
    const emonth = (selectedEndDate.getMonth() + 1).toString().padStart(2, '0');
    const eyear = selectedEndDate.getFullYear().toString();

    const formattedDate = `${day}/${month}/${year} - ${eday}/${emonth}/${eyear}`;
    saveAs(fileData, `${formattedDate}-orders.xlsx`);
  };

  return (
    <>
      <Helmet>
        <title> Order History | Albarakah Fusion </title>
      </Helmet>
      <div className={classes.sectionHistory}>
        <Typography variant="h4">Order History</Typography>
        <div className={classes.subSection}>
          <DateRangePickerComponent
            startDate={startDate}
            endDate={endDate}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
            onSearch={handleSearch}
          />
        </div>
        <Button variant="contained" className={classes.expBtn} onClick={exportOrders}>
          Export Excel
        </Button>
      </div>
      {data || (data && data.length > 0) ? (
        <OrdersTable orders={data} />
      ) : (
        <p
          style={{
            textAlign: 'center',
          }}
        >
          Please choose Start Date & End Date and click on SEARCH. Choose only upto 30 days.
        </p>
      )}
    </>
  );
};

export default History;
