import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import { db } from '../services/firebase';

const { apiSlice } = require('./apiSlice');

const userSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUserById: build.query({
      queryFn: async (docId) => {
        try {
          const collection = 'admin';
          const docRef = doc(db, collection, docId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            // console.log("Document data:", docSnap.data());
            return {
              data: docSnap.data(),
            };
          }
          // docSnap.data() will be undefined in this case
          return {
            // only returns data, error, meta
            error: {
              name: 'invalid_id',
              message: `No such document - path: ${collection}/${docId}`,
              data: '',
            },
          };
        } catch (e) {
          return {
            error: {
              name: 'getItemById',
              message: e?.message || 'error',
              data: e,
            },
          };
        }
      },
    }),
    getUsers: build.query({
      queryFn: async () => {
        try {
          const collectionName = 'users';
          const querySnapshot = await getDocs(collection(db, collectionName));

          const users = [];

          querySnapshot.forEach((doc) => {
            const user = doc.data();
            users.push({
              id: doc.id,
              name: user?.name || '',
              ...doc.data(),
            });
          });

          return {
            data: users,
          };
        } catch (e) {
          return {
            error: {
              name: 'getItemById',
              message: e?.message || 'error',
              data: e,
            },
          };
        }
      },
    }),
  }),
});

export const { useGetUserByIdQuery, useGetUsersQuery } = userSlice;
