import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/query/react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../services/firebase';

export const apiSlice = createApi({
  baseQuery: fakeBaseQuery(),
  endpoints: (build) => ({
    getSettings: build.query({
      queryFn: async () => {
        try {
          const collection = 'appMeta';
          const docId = 'settings';
          const docRef = doc(db, collection, docId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            // console.log("Document data:", docSnap.data());
            return {
              data: docSnap.data(),
            };
          }
          // docSnap.data() will be undefined in this case
          return {
            // only returns data, error, meta
            error: {
              name: 'invalid_id',
              message: `No such document - path: ${collection}/${docId}`,
              data: '',
            },
          };
        } catch (e) {
          return {
            error: {
              name: 'getGettings',
              message: e?.message || 'error',
              data: e,
            },
          };
        }
      },
    }),
  }),
});

export const { useGetSettingsQuery } = apiSlice;
