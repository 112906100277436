import React from 'react';
import { QRCode } from 'react-qrcode-logo';
import { Link } from 'react-router-dom';

const QrCode = ({ url, type, qrCodeUrl }) => {
  const valueToDisplay = type === 'add' ? qrCodeUrl : url;
  return (
    <>
      <Link>{valueToDisplay}</Link>
      <QRCode
        value={valueToDisplay} // here you should keep the link/value(string) for which you are generation promocode
        size={150}
        // logoImage="https://firebasestorage.googleapis.com/v0/b/albarakah-fusion.appspot.com/o/albarakah%20logo%20with%20slogan%20(1).jpg?alt=media" // URL of the logo you want to use, make sure it is a dynamic url
        // logoHeight={60}
        // // removeQrCodeBehindLogo
        // logoWidth={60}
        // logoOpacity={0.8} // the dimension of the QR code (number)
        qrStyle="square" // type of qr code, wether you want dotted ones or the square ones
        eyeRadius={10} // radius of the promocode's eye
      />
    </>
  );
};

export default QrCode;
