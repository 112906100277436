import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function StatusSelect({ type, inputValue, onSelect, onChoose }) {
  const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    // console.log('select',event.target.value)
    setValue(event.target.value);
    if (type === 'payment') {
      onSelect(event.target.value); // Call onSelect for payment method
    } else if (type === 'status') {
      onChoose(event.target.value); // Call onChoose for status
    }
  };

  return (
    <Box sx={{ minWidth: 120, mb: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{type === 'payment' ? 'Payment Method' : 'Status'}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={type === 'payment' ? 'Payment Method' : 'Status'}
          onChange={handleChange}
        >
          {inputValue.map((val, idx) => (
            <MenuItem value={val} key={idx}>
              {val}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
