import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// firebase
import { signInWithEmailAndPassword, getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../services/firebase';

// components
import Iconify from '../../../components/iconify';

// utils
import { isValidEmail, isValidPassword } from '../../../utils/validator';
import { getUserById } from '../../../api/auth';
import { login } from '../../../store/userSlice';
import { errorNotification, successNotification } from '../../../utils/notifications';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);

  // Helper text for password rules
  const passwordRulesTooltip = (
    <>
      Password must:
      <br />
      - Be at least 8 characters long
      <br />
      - Contain at least one lowercase letter
      <br />
      - Contain at least one uppercase letter
      <br />
      - Contain at least one digit
      <br />- Contain at least one special character (!@#$%^&*()_+{}[]|;:'",.{`<>`}?`~)
    </>
  );

  const handleForgetPassword = () => {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        successNotification('Link sent to your email id !!');
      })
      .catch((err) => {
        errorNotification(`${err.name} - ${err.code} - ${err.message}`);
      });
    setForgetPassword(false);
  };

  const handleLogin = async () => {
    try {
      if (isValidEmail(email) && isValidPassword(password)) {
        // console.log('email: ', email, password);
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const { uid } = userCredential.user;

        const { success, data, err, message } = await getUserById(uid);

        if (success) {
          dispatch(login(data));
          successNotification('Successfully Logged In!!!');
          navigate('/dashboard', { replace: true });
        } else {
          console.log('handleLogin: ', err);
          errorNotification(`${err.name} - ${err.code} - ${err.message}`);
        }
      } else {
        errorNotification('Your email or password is incorrect, please try again with correct details.');
      }
    } catch (error) {
      console.log('handleLogin: ', error);
      errorNotification(error.message);
    }
  };

  return !forgetPassword ? (
    <>
      <Typography variant="h4" gutterBottom>
        Sign in to Albarakah Fusion
      </Typography>
      <Stack spacing={3}>
        <TextField name="email" label="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />

        <TextField
          name="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
                <Tooltip title={passwordRulesTooltip} arrow edge="end">
                  <IconButton>
                    <Iconify icon="material-symbols:question-mark" /> {/* Replace with your question mark icon */}
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          // Add helper text to display password rules
          // helperText={passwordRulesTooltip}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <FormControlLabel control={<Checkbox name="remember" defaultChecked />} label="Remember me" />
        <Link variant="subtitle2" underline="hover" sx={{ cursor: 'pointer' }} onClick={() => setForgetPassword(true)}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleLogin}>
        Login
      </LoadingButton>
    </>
  ) : (
    <>
      <Typography variant="h4" gutterBottom>
        Forget Password
      </Typography>
      <br />
      <TextField name="email" label="Email address" value={email} onChange={(e) => setEmail(e.target.value)} />
      <br />
      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleForgetPassword}>
        Submit
      </LoadingButton>
    </>
  );
}
