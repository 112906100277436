import PropTypes from 'prop-types';
import { Box, Card, Typography, Stack, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FiberManualRecord, RadioButtonUnchecked } from '@mui/icons-material';
import classes from './ProductCard.module.css';
import BasicMenu from '../../../reusable/modal/menu/Menu';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '140px',
  objectFit: 'cover',
  position: 'absolute',
});

const totalcount = {
  fontSize: '12px',
};
const itemprice = {
  fontSize: '12px',
};

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function   ShopProductCard({ product }) {
  const { name, category, subCategory, imgUrl, isAvailable, itemPrice, minItemCount, totalItemCount,description } = product;
  // console.log('product', product);
  return (
    <>
      <Card className={classes.productcard}>
        <Box className={classes.imgContainer}>
          {/* {status && (
          <Label
            variant="filled"
            color={(status === 'sale' && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {status}
          </Label>
        )} */}
          <StyledProductImg alt={name} src={imgUrl} />
        </Box>

        <Stack spacing={2} sx={{ p: 3 }}>
          <div className={classes.menu}>
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
            <BasicMenu items={product} />
          </div>

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {/* <ColorPreview colors={colors} /> */}
            <div>
              {isAvailable ? (
                <FiberManualRecord sx={{ color: 'green', fontSize: '1rem' }} />
              ) : (
                <RadioButtonUnchecked sx={{ color: 'red', fontSize: '1rem' }} />
              )}
              {/* <p>{isOnline ? 'Online' : 'Offline'}</p> */}
            </div>
            <Typography variant="subtitle1">{`RM ${itemPrice}`}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={totalcount} component="span" variant="body1">
              {category.name}
            </Typography>
            <Typography sx={totalcount} component="span" variant="body1">
              {subCategory.name}
            </Typography>
          </Stack>
          <Divider />
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={totalcount} component="span" variant="body1">
              Total Count : {totalItemCount}
            </Typography>
            <Typography sx={totalcount} component="span" variant="body1">
              Min Count : {minItemCount}
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </>
  );
}
