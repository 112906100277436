import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import OrdersTable from '../table/OrdersTable';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
          {/* <Typography>{children}</Typography> */}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CurrentOrdersTabs({ type, values }) {
  console.log('CurrentOrdersTabs: ', type, values);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tab = {
    // fontWeight: '700',
    // background: '#9F3239',
    // color: '#fff',
    // outline: 'none',
    // borderRadius: '5px',
    // fontFamily: 'sans-serif',
    // fontSize: '16px',
    // borderBottom: 'none',
    '&.Mui-selected': {
      fontWeight: '700',
      background: '#000',
      color: '#b68e1d',
      outline: 'none',
      borderRadius: '5px',
      fontFamily: 'sans-serif',
      fontSize: '16px',
      borderBottom: 'none',
    },
    '&.css-6d3pg0-MuiTabs-indicator': {
      background: '#fff',
      outline: 'none!important',
      display: 'none!important',
    },
  };

  return (
    <>
      <Box sx={{ width: '100%', marginTop: '30px' }}>
        <Box>
          <Tabs value={value} onChange={handleChange} aria-label={type}>
            <Tab sx={tab} label="All" {...a11yProps(0)} />
            <Tab sx={tab} label="Booked" {...a11yProps(1)} />
            <Tab sx={tab} label="Preparing" {...a11yProps(2)} />
            <Tab sx={tab} label="Completed" {...a11yProps(3)} />
            <Tab sx={tab} label="Cancelled" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <OrdersTable orders={values?.all} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <OrdersTable orders={values?.booked} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <OrdersTable orders={values?.preparing} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <OrdersTable orders={values?.completed} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <OrdersTable orders={values?.cancelled} />
        </CustomTabPanel>
      </Box>
    </>
  );
}
