import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { Divider, FormControl, Grid, InputLabel, Stack } from '@mui/material';
import { collection, addDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../../../Firebase/config';
import ItemCategorySelect from '../CategorySelect/ItemCategorySelect';
import ProfilePic from '../ProfilePic';

import AvailabilitySwitch from './AvailabilitySwitch';
import { useGetSettingsQuery } from '../../../api/apiSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

const modal = {
  background: '#000',
  color: '#b68e1d',
  '&:hover': {
    background: '#000',
    color: '#b68e1d',
  },
};
const cancel = {
  border: '1px solid #000',
  color: '#000',
  '&:hover': {
    border: '1px solid #000',
    color: '#000',
  },
};

const textfield = {
  marginTop: '20px',
  minWidth: '88%',
};
const textfieldDesc = {
  marginTop: '20px',
  minWidth: '90%',
};

export default function ItemModal({ open, onClose, handleOpen, data, selectedType }) {
  // console.log('data1', data, data?.id);
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  const [name, setName] = useState(data?.name || '');
  const [itemPrice, setItemPrice] = useState(data?.itemPrice || []);
  const [category, setCategory] = useState(data?.category || { id: '', name: '', subcategories: [] });
  const [subCategory, setSubCategory] = useState(data?.subCategory || { id: '', name: '' });
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [foodType, setFoodType] = useState(data?.foodType || '');
  const [totalItemCount, setTotalItemCount] = useState(data?.totalItemCount || '');
  const [minItemCount, setMinItemCount] = useState(data?.minItemCount || '');
  const [isAvailable, setIsAvailable] = useState(data?.isAvailable || false);
  const [fileName, setFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [photoUrl, setPhotoUrl] = useState(data?.url || '');
  const [description, setDescription] = useState(data?.description || '');

  const { data: result, isError, isFetching, isLoading } = useGetSettingsQuery();
  // console.log('settings', result, isError, isFetching, isLoading);

  const [categoryList, setCategoryList] = useState(data?.categories || []);

  // console.log('catz: ', data?.categories);

  // console.log('categoryList: ', categoryList);

  useEffect(() => {
    // console.log('category: ', data);
    if (!data && result?.categories) {
      setCategory(result.categories[0]);
      setCategoryList(result.categories);
      setSubCategory(result.categories[0].subcategories[0]);
      setSubCategoryList(result.categories[0].subcategories);
    } else if (result) {
      const categoriesData = result?.categories;
      const matchingCategory = categoriesData?.find((category) => category?.name === data?.category?.name);
      // console.log('match: ', matchingCategory);
      setCategory(matchingCategory);
      setCategoryList(categoriesData);
      const matchingSubcategory = matchingCategory?.subcategories?.find(
        (scat) => scat?.name === data?.subCategory?.name
      );
      setSubCategory(matchingSubcategory);
      setSubCategoryList(matchingCategory?.subcategories);
      // console.log('data', result);
      // setSubCategory(result?.categories[0].subcategories[0]);
      // setSubCategoryList(result?.categories[0].subcategories);
    }
  }, [data, result]);

  // useEffect(() => {
  //   const fetchCategories = async () => {
  //     const docRef = doc(db, 'appMeta', 'settings');
  //     const docSnap = await getDoc(docRef);

  //     if (docSnap.exists()) {
  //       const categoriesData = docSnap.data().categories;
  //       console.log('Categories data:', categoriesData);

  //       // Find the matching category from data.category in categoriesData
  //       const matchingCategory = categoriesData.find((category) => category.name === data?.category);

  //       if (matchingCategory) {
  //         // Set the category and subCategory based on the matching data
  //         setCategory({
  //           id: matchingCategory.id,
  //           name: matchingCategory.name,
  //         });
  //         setSubCategory({
  //           id: matchingCategory.subcategories[0].id,
  //           name: matchingCategory.subcategories[0].name,
  //         });

  //         // Set the category list and subCategory list
  //         setCategoryList(categoriesData);
  //         setSubCategoryList(matchingCategory.subcategories);
  //       } else {
  //         // If there is no match, use the first category in the list as the default
  //         setCategory({
  //           id: matchingCategory.id,
  //           name: matchingCategory.name,
  //         });
  //         setSubCategory({
  //           id: matchingCategory.subcategories[0].id,
  //           name: matchingCategory.subcategories[0].name,
  //         });
  //         setCategoryList(categoriesData);
  //         setSubCategoryList(categoriesData[0].subcategories);
  //       }
  //     } else {
  //       console.log('No such document!');
  //     }
  //   };
  //   fetchCategories();
  // }, []);

  const handleFileName = (imgName) => {
    console.log('image name:', imgName);
    setFileName(imgName);
  };
  const handleFile = (data) => {
    console.log('fileInfo:', data);
    setFile(data);
  };
  const handleCategory = (val) => {
    console.log('receiveedVal', val);
    // const { id, name } = val;
    setCategory(val);
    // const cate = categoryList.find((catDetail) => catDetail.name === name);
    console.log('subcate: ', val.subcategories[0]);
    setSubCategory(val.subcategories[0]);
    setSubCategoryList(() => [...val.subcategories]);
  };

  const handleSubCategory = (subVal) => {
    console.log('subVal', subVal);
    setSubCategory(subVal);
  };

  const handleType = (typeVal) => {
    console.log('typeVal', typeVal);
    setFoodType(typeVal.name);
  };
  const handleToggle = (bool) => {
    console.log('bool', bool);
    setIsAvailable(bool);
  };
  // empty
  const clearOut = () => {
    setName('');
    setItemPrice('');
    // setCategory('');
    // setSubCategory('');
    setCategory(result.categories[0]);
    setCategoryList(result.categories);
    setSubCategory(result.categories[0].subcategories[0]);
    setSubCategoryList(result.categories[0].subcategories);
    setFoodType('');
    setTotalItemCount('');
    setMinItemCount('');
    setIsAvailable(false);
    setFileName(null);
    setFile(null);
    setPhotoUrl('');
    setDescription();
  };
  // end empty
  // update
  const handleUpdate = async (e) => {
    e.preventDefault();

    const updatedFields = {};

    if (data?.name !== name) {
      updatedFields.name = name;
    }

    if (data?.description !== description) {
      updatedFields.description = description;
    }

    if (data?.itemPrice !== itemPrice) {
      updatedFields.itemPrice = parseFloat(itemPrice);
    }

    if (data?.totalItemCount !== totalItemCount) {
      updatedFields.totalItemCount = parseInt(totalItemCount, 10);
    }

    if (data?.minItemCount !== minItemCount) {
      updatedFields.minItemCount = parseInt(minItemCount, 10);
    }

    if (data?.category.name !== category.name) {
      updatedFields.category = {
        id: category.id,
        name: category.name,
      };
    }

    if (data?.subCategory.name !== subCategory.name) {
      updatedFields.subCategory = {
        id: subCategory.id,
        name: subCategory.name,
      };
    }

    if (data?.foodType !== foodType) {
      updatedFields.foodType = foodType;
    }
    if (data?.isAvailable !== isAvailable) {
      updatedFields.isAvailable = isAvailable;
    }

    if (file) {
      const storage = getStorage();
      const storageRef = ref(storage, `/images/items/${fileName}`);

      // 'file' comes from the Blob or File API
      await uploadBytes(storageRef, file).then((snapshot) => {
        console.log('Uploaded a blob or file!');
      });

      const downloadURL = await getDownloadURL(storageRef);
      updatedFields.imgUrl = downloadURL;
    }
    if (Object.keys(updatedFields).length > 0) {
      const updateRef = doc(db, 'items', data?.id);
      console.log('updatedfields:', updatedFields);
      await updateDoc(updateRef, {
        ...updatedFields,
      });

      alert('Items Updated!');
    } else {
      alert('No changes made.');
    }
    clearOut();
    onClose();
  };

  // end update
  const submitHandler = async (e) => {
    e.preventDefault();
    if (
      name.trim().length === 0 ||
      itemPrice.trim().length === 0 ||
      category.name.trim().length === 0 ||
      subCategory.name.trim().length === 0 ||
      foodType.trim().length === 0 ||
      totalItemCount.trim().length === 0 ||
      // file === null ||
      minItemCount.trim().length === 0 ||
      description.trim().length === 0
    ) {
      alert('Fill the empty fields');
    } else if (Number.isNaN(totalItemCount) || Number.isNaN(itemPrice) || Number.isNaN(minItemCount)) {
      alert('Price,Totalcount,Minimumcount must be number');
    } else {
      let downloadURL =
        'https://firebasestorage.googleapis.com/v0/b/albarakah-fusion-4afcb.appspot.com/o/images%2Fthumbnail-default%402x.png?alt=media';

      if (file !== null) {
        const storage = getStorage();
        const storageRef = ref(storage, `/images/items/${fileName}`);

        // 'file' comes from the Blob or File API
        await uploadBytes(storageRef, file).then((snapshot) => {
          console.log('Uploaded a blob or file!');
        });

        downloadURL = await getDownloadURL(storageRef);
      }
      // creating image file path in firebase storage

      // https://firebasestorage.googleapis.com/v0/b/albarakah-fusion-4afcb.appspot.com/o/images%2Fthumbnail-default%402x.png?alt=media

      const updatedData = {
        description,
        category,
        foodType,
        imgUrl: downloadURL,
        isAvailable,
        itemPrice: parseFloat(itemPrice),
        name,
        minItemCount: parseInt(minItemCount, 10),
        subCategory,
        totalItemCount: parseInt(totalItemCount, 10),
      };
      console.log('updatedData: ', updatedData);
      const docRef = await addDoc(collection(db, 'items'), updatedData);
      console.log('Document written with ID: ', docRef.id);
      alert('items Added');
      clearOut();
      onClose();
    }
  };
  return (
    <div>
      <Button sx={modal} onClick={handleOpen}>
        Create Item
      </Button>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create Item
          </Typography>
          <Divider />
          <Grid spacing={2} container sx={{ marginTop: '10px' }}>
            <Grid md={4} lg={4}>
              <ProfilePic getImage={handleFileName} getFile={handleFile} selectedUrl={photoUrl} />
            </Grid>
            <Grid md={8} lg={8}>
              <Grid spacing={2} container sx={{ marginTop: '10px' }}>
                <Grid md={6} lg={6}>
                  <TextField
                    id="outlined-basic"
                    type="text"
                    sx={textfield}
                    label="Item Name"
                    variant="outlined"
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    value={name}
                    required
                  />
                </Grid>
                <Grid md={6} lg={6}>
                  <TextField
                    id="outlined-basic"
                    type="number"
                    sx={textfield}
                    label="price"
                    variant="outlined"
                    onChange={(e) => {
                      setItemPrice(e.target.value);
                    }}
                    value={itemPrice}
                    required
                  />
                </Grid>
              </Grid>
              <Grid spacing={2} container sx={{ marginTop: '10px' }}>
                <Grid md={6} lg={6}>
                  <ItemCategorySelect
                    label="Category"
                    title="Category"
                    list={categoryList}
                    setItem={handleCategory}
                    value={category.name}
                  />
                </Grid>
                <Grid md={6} lg={6}>
                  <ItemCategorySelect
                    label="Sub Category"
                    title="Sub Category"
                    list={subCategoryList}
                    handleSubCategory={handleSubCategory}
                    value={subCategory.name}
                  />
                </Grid>
              </Grid>
              <Grid spacing={2} container sx={{ marginTop: '10px' }}>
                <Grid md={6} lg={6}>
                  <ItemCategorySelect
                    label="Food Type"
                    title="Food Type"
                    list={[
                      { id: 'Veg', name: 'Veg' },
                      { id: 'Non-Veg', name: 'Non-Veg' },
                    ]}
                    setType={handleType}
                    value={foodType}
                  />
                </Grid>
                <Grid md={6} lg={6} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                  <InputLabel
                    sx={{ display: 'flex', justifyContent: 'start', alignItems: 'end' }}
                    id="demo-simple-select-label"
                  >
                    Availability : <AvailabilitySwitch onToggle={handleToggle} selectedToggle={isAvailable} />
                  </InputLabel>
                </Grid>
              </Grid>

              <Grid md={12} lg={12}>
                <TextField
                  id="outlined-basic"
                  type="text"
                  sx={textfieldDesc}
                  label="Description"
                  variant="outlined"
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  value={description}
                  required
                />
              </Grid>

              <Grid spacing={2} container sx={{ marginTop: '10px' }}>
                <Grid md={6} lg={6}>
                  <InputLabel
                    sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}
                    id="demo-simple-select-label"
                  >
                    Total item count :{' '}
                    <TextField
                      sx={{ width: '30%', marginLeft: '10px' }}
                      id="outlined-basic"
                      type="number"
                      variant="outlined"
                      onChange={(e) => {
                        setTotalItemCount(e.target.value);
                      }}
                      value={totalItemCount}
                      required
                    />
                  </InputLabel>
                </Grid>
                <Grid md={6} lg={6}>
                  <InputLabel
                    sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}
                    id="demo-simple-select-label"
                  >
                    minimum item count :{' '}
                    <TextField
                      sx={{ width: '30%', marginLeft: '10px' }}
                      id="outlined-basic"
                      type="number"
                      variant="outlined"
                      onChange={(e) => {
                        setMinItemCount(e.target.value);
                      }}
                      value={minItemCount}
                      required
                    />
                  </InputLabel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Stack spacing={2} direction="row" justifyContent="end" alignItems="center" sx={{ marginTop: '20px' }}>
            <Button
              variant="outlined"
              sx={cancel}
              onClick={() => {
                clearOut();
                onClose();
              }}
            >
              Cancel
            </Button>
            {selectedType === 'selected' ? (
              <Button variant="contained" sx={modal} onClick={handleUpdate}>
                Update Item
              </Button>
            ) : (
              <Button variant="contained" sx={modal} onClick={submitHandler}>
                Create Item
              </Button>
            )}
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
