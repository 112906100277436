import { Button } from '@mui/material';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { QRCode } from 'react-qrcode-logo';
import classes from './DownloadQr.module.css';

const DownloadCode = ({ url }) => {
  const downloadCode = () => {
    const canvas = document.getElementById('QR');
    if (canvas) {
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `albarakah.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const link = url;
  return (
    <>
      <div className={classes.sectiondqr}>
        <QRCode
          value={link} // here you should keep the link/value(string) for which you are generation promocode
          size={200}
          // logoImage="https://firebasestorage.googleapis.com/v0/b/albarakah-fusion.appspot.com/o/albarakah%20logo%20with%20slogan%20(1).jpg?alt=media" // URL of the logo you want to use, make sure it is a dynamic url
          // logoHeight={60}
          // logoWidth={60}
          // logoOpacity={0.8}
          enableCORS
          qrStyle="sqauare" // type of qr code, wether you want dotted ones or the square ones
          eyeRadius={10} // radius of the promocode eye
          id={'QR'}
        />
      </div>

      <div className={classes.sectiondqr}>
        {/* <DownloadForOfflineIcon onClick={() => downloadCode()} sx={{ cursor: 'pointer' }} /> */}

        <Button variant="contained" onClick={() => downloadCode()} className={classes.textBtn}>
          Download Code
        </Button>
      </div>
    </>
  );
};

export default DownloadCode;
