import PropTypes from 'prop-types';
import { set, sub } from 'date-fns';
import { noCase } from 'change-case';
import { faker } from '@faker-js/faker';
import { useEffect, useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  TextField,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { useGetOutOfStockItemsQuery, useUpdateItemStockMutation } from '../../../api/itemSlice';
import { errorNotification } from '../../../utils/notifications';

// ----------------------------------------------------------------------

const NOTIFICATIONS = [
  {
    id: faker.datatype.uuid(),
    title: 'Tandoori Chicken ',
    description: '',
    avatar: '../../images/images.jpg',
    type: 'friend_interactive',
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true,
  },
  {
    id: faker.datatype.uuid(),
    title: 'Table 2 ',
    description: 'waiting for request',
    avatar: '../../images/images.jpg',
    type: 'friend_interactive',
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true,
  },
  {
    id: faker.datatype.uuid(),
    title: 'Table 3 ',
    description: 'waiting for request',
    avatar: '../../images/images.jpg',
    type: 'friend_interactive',
    createdAt: sub(new Date(), { hours: 3, minutes: 30 }),
    isUnRead: true,
  },

  {
    id: faker.datatype.uuid(),
    title: 'You have new message',
    description: '5 unread messages',
    avatar: null,
    type: 'chat_message',
    createdAt: sub(new Date(), { days: 1, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {
    id: faker.datatype.uuid(),
    title: 'You have new mail',
    description: 'sent from Guido Padberg',
    avatar: null,
    type: 'mail',
    createdAt: sub(new Date(), { days: 2, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
  {
    id: faker.datatype.uuid(),
    title: 'Delivery processing',
    description: 'Your order is being shipped',
    avatar: null,
    type: 'order_shipped',
    createdAt: sub(new Date(), { days: 3, hours: 3, minutes: 30 }),
    isUnRead: false,
  },
];

export default function StockPopover() {
  const { data, isError, isFetching, isLoading } = useGetOutOfStockItemsQuery();
  // console.log('useGetOutOfStockItemsQuery: ', data, isError, isFetching, isLoading);
  const [updateItemStockMutation, { isLoading: isUpdateLoading, isError: isUpdateError, isSuccess, data: result }] =
    useUpdateItemStockMutation();

  // const [notifications, setNotifications] = useState(NOTIFICATIONS);
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    if (data) {
      setNotifications(data);
    }
  }, [data]);

  // const totalUnRead = notifications.filter((item) => item.isUnRead === true).length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        isUnRead: false,
      }))
    );
  };

  const tablerequest = {
    maxHeight: '300px',
    overflowY: 'scroll',
  };
  const clear = {
    background: '#fff',
    color: '#b68e1d',
  };

  return (
    <>
      <IconButton color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
        <Badge badgeContent={notifications?.length || 0} color="error">
          <Iconify icon="ant-design:stock-outlined" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 600,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Out of Stock </Typography>
            {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography> */}
          </Box>

          {/* {totalUnRead > 0 && <Button sx={clear}>Clear all</Button>} */}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List sx={tablerequest} disablePadding>
            {notifications.map((notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                updateStockItem={(id, newStockValue) => {
                  // console.log('123: ', id, newStockValue);
                  updateItemStockMutation({ id, newStockValue });
                }}
              />
            ))}
          </List>
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  updateStockItem: PropTypes.func,
  notification: PropTypes.shape({
    // createdAt: PropTypes.instanceOf(Date),
    // id: PropTypes.string,
    // isUnRead: PropTypes.bool,
    // description: PropTypes.string,
    // type: PropTypes.string,
    // title: PropTypes.string,
    // avatar: PropTypes.any,
    id: PropTypes.string,
    name: PropTypes.string,
    imgUrl: PropTypes.string,
    minItemCount: PropTypes.number,
    totalItemCount: PropTypes.number,
    isAvailable: PropTypes.bool,
    desc: PropTypes.string,
    foodType: PropTypes.string,
    itemPrice: PropTypes.number,
    category: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
    subCategory: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
};

function NotificationItem({ notification, updateStockItem }) {
  const { title, avatar } = renderContent(notification);
  const [stock, setStock] = useState(notification?.totalItemCount || 0);

  const approved = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  };

  const stockupdate = {
    width: '50%',
  };

  const handleStockChange = (e) => {
    setStock(e.target.value);
  };

  return (
    <>
      <Box>
        <ListItemButton
          sx={{
            py: 1,
            px: 1,
            mt: '1px',
            // ...(notification.isUnRead && {
            //   bgcolor: 'action.selected',
            // }),
          }}
        >
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
          </ListItemAvatar>
          <ListItemText
            // primary={notification?.name}
            secondary={
              <>
                {title}
                <Typography
                  variant="caption"
                  sx={{
                    mt: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    color: 'text.disabled',
                  }}
                >
                  Minimum Stock Limit : {notification?.minItemCount}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    mt: 0.5,
                    display: 'flex',
                    alignItems: 'center',
                    color: 'text.disabled',
                  }}
                >
                  Current Stock : {notification?.totalItemCount}
                </Typography>
              </>
            }
          />

          <Box sx={approved}>
            <TextField
              sx={stockupdate}
              id="outlined-basic"
              label="Qty"
              variant="outlined"
              value={stock}
              type="number"
              onChange={handleStockChange}
            />
          </Box>
          <div style={approved}>
            <IconButton
              onClick={() => {
                const stockVal = parseInt(stock, 10);
                if (stockVal !== notification.totalItemCount && stockVal > notification.minItemCount) {
                  updateStockItem(notification.id, stockVal);
                } else {
                  errorNotification(
                    'Stock update should be greater than minimum item count & should not be equal to same value'
                  );
                }
              }}
            >
              <CheckCircleIcon sx={{ color: 'green' }} />
            </IconButton>
            {/* <IconButton>
              <CancelIcon sx={{ color: 'red' }} />
            </IconButton> */}
          </div>
        </ListItemButton>
      </Box>
    </>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2" sx={{ color: '#b68e1d' }}>
      {notification.name}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification?.description || '')}
      </Typography>
    </Typography>
  );

  return {
    avatar: notification.imgUrl ? <img alt={notification.name} src={notification.imgUrl} /> : null,
    title,
  };
}
