import { useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Divider, FormControl, InputLabel, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Select from '@mui/material/Select';
import { useGetAllTablesQuery } from '../../../api/tableSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

export default function SwapModal({ open, handleClose, item, handleSwapTableOrders }) {
  const { data } = useGetAllTablesQuery(undefined);
  // console.log(('swapOrder', data));
  const [table, setTable] = useState('');

  const handleChange = (event) => {
    setTable(event.target.value);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Choose table to swap orders from: {item?.name}
          </Typography>
          <Divider />
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Table to be swaped/switched</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={table}
                label="Tables"
                onChange={handleChange}
              >
                {data?.map((table) => (
                  <MenuItem key={table.id} value={table.id}>
                    {table.name}
                  </MenuItem>
                ))}
              </Select>
              <Button
                type="button"
                variant="contained"
                sx={{
                  marginTop: '20px',
                  color: '#B68E1C',
                  backgroundColor: '#000000',
                  '&:hover': {
                    backgroundColor: '#333333', // Darker shade or the same color on hover
                  },
                }}
                onClick={() => handleSwapTableOrders(item, table)}
              >
                Swap Order
              </Button>
            </FormControl>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
