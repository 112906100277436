import { toast } from 'react-toastify';
import toastSound from '../assets/sound/toast_sound.mp3';
import iPhoneSound from '../assets/sound/iphone_sound.mp3';

const successNotification = (message, playSound, isNewTable) => {
  toast.success(message, {
    position: isNewTable ? toast.POSITION.BOTTOM_RIGHT : toast.POSITION.TOP_RIGHT,
  });
  if (playSound) {
    const audioUrl = isNewTable ? toastSound : iPhoneSound;
    const audio = new Audio(audioUrl);
    // audio.loop = true;
    audio.play();
  }
};

const errorNotification = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

const infoNotification = (message) => {
  toast.info(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

const warningNotification = (message) => {
  toast.warning(message, {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export { successNotification, errorNotification, infoNotification, warningNotification };
