import React, { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';

const Availability = {
  marginTop: '10px',
  marginLeft: '10px',
  marginBottom: '3px',
};

export default function AvailabilitySwitch({ onToggle, selectedToggle }) {
  const [isChecked, setIsChecked] = useState(selectedToggle || false);

  const handleToggle = (event) => {
    const newValue = event.target.checked;
    setIsChecked(newValue); // Notify the parent component of the state change
    // console.log('toggle', newValue);
    onToggle(newValue);
  };

  return (
    <FormGroup>
      <Stack direction="row" spacing={1} sx={Availability} justifyContent="center" alignItems="center">
        <Switch checked={isChecked} onChange={handleToggle} inputProps={{ 'aria-label': 'availability' }} />
      </Stack>
    </FormGroup>
  );
}
