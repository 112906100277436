import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Tooltip } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import classes from './QrModal.module.css';
import QrCode from './QrCode';
import DownloadCode from './DownloadQr';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
};

export default function QrModal({ qrUrl }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Tooltip title="View/Download QR Code" placement="top">
        <PreviewIcon className={classes.previewIcon} onClick={handleOpen} />
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <QrCode url={qrUrl} /> */}
          <DownloadCode url={qrUrl} />
        </Box>
      </Modal>
    </div>
  );
}
