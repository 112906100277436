import { collection, getDocs, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { db } from '../services/firebase';
import { successNotification } from '../utils/notifications';
import { getSingaporeDate } from '../utils';
import { notify, selectUserDetail } from '../store/userSlice';

const { apiSlice } = require('./apiSlice');

const orderSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCurrentOrders: build.query({
      queryFn: async () => ({ data: null }),
      onCacheEntryAdded: async (_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch, getState }) => {
        // _ as 1st param, it requried to get 2nd param, but not using, so kept as _
        let unsubscribe = () => {};
        try {
          await cacheDataLoaded;
          // Get the current date and time
          const currentDate = getSingaporeDate();
          // Get the start time of today (midnight)
          const todayStartTime = new Date(currentDate);
          todayStartTime.setHours(0, 0, 0, 0);

          // Get the start time of tomorrow (midnight)
          const tomorrowStartTime = new Date(currentDate);
          tomorrowStartTime.setDate(currentDate.getDate() + 1);
          tomorrowStartTime.setHours(0, 0, 0, 0);

          // console.log('time: ', todayStartTime.getTime(), tomorrowStartTime.getTime());

          const userDetail = selectUserDetail(getState());
          // console.log('userDetail.branch---------------', userDetail.branch);

          const currentOrdersRef = query(
            collection(db, 'orders'),
            where('branch', '==', userDetail.branch),
            where('orderedTimestamp', '>=', todayStartTime.getTime()),
            where('orderedTimestamp', '<', tomorrowStartTime.getTime()),
            orderBy('orderedTimestamp', 'desc')
          );

          unsubscribe = onSnapshot(currentOrdersRef, (snapshot) => {
            // console.log('snapshot', snapshot.size);
            const orders = {
              dineIn: {
                all: [],
                booked: [],
                preparing: [],
                completed: [],
                cancelled: [],
                newItems: 0,
              },
              takeAway: {
                // check status for takeaway, it has different ones
                all: [],
                booked: [],
                preparing: [],
                completed: [],
                cancelled: [],
                newItems: 0,
              },
            };
            snapshot.docs.forEach((doc) => {
              const data = {
                id: doc.id,
                ...doc.data(),
              };
              if (data.type === 'dine-in') {
                orders.dineIn.all.push(data); // all
                orders.dineIn[data.status].push(data);
                if (data.status === 'booked') orders.dineIn.newItems += 1;
              } else {
                // take-away
                orders.takeAway.all.push(data); // all
                orders.takeAway[data.status].push(data);
                if (data.status === 'booked') orders.takeAway.newItems += 1;
              }
            });

            snapshot.docChanges().forEach((change) => {
              const data = {
                id: change.doc.id,
                ...change.doc.data(),
              };

              if (change.type === 'added') {
                // console.log('New Order: ', orders);
                if (data.type === 'dine-in') {
                  // orders.dineIn.all.push(data); // all
                  // orders.dineIn[data.status].push(data);
                  if (data.status === 'booked') {
                    successNotification(`New Dine-In Order: ${data.orderNo} in Table: ${data.tableNo}`);
                    dispatch(notify(true));
                    orders.dineIn.newItems += 1;
                  } else {
                    dispatch(notify(false));
                  }
                } else if (data.type === 'take-away') {
                  // take-away
                  // orders.takeAway.all.push(data); // all
                  // orders.takeAway[data.status].push(data);
                  if (data.status === 'booked') {
                    successNotification(`New Take-Away Order: ${data.orderNo}`);
                    dispatch(notify(true));
                    orders.takeAway.newItems += 1;
                  } else {
                    dispatch(notify(false));
                  }
                }
              }

              if (change.type === 'modified') {
                // console.log('Modified Order: ', change.doc.data());
                if (data.type === 'dine-in') {
                  // orders.dineIn.all.push(data); // all
                  // orders.dineIn[data.status].push(data);

                  if (data.status === 'booked') {
                    orders.dineIn.newItems += 1;
                  } else if (data.status === 'preparing') {
                    // if preparing, check suborder any booked is there, then consider that as new and play sound
                    let noOfNewSuborders = 0;
                    data.orderedItems.forEach((subOrder) => {
                      if (subOrder.status === 'booked') {
                        noOfNewSuborders += 1;
                      }
                    });

                    if (noOfNewSuborders > 0) {
                      successNotification(`New Dine-In Sub-Order: ${data.orderNo} in Table: ${data.tableNo}`);
                      dispatch(notify(true));
                    } else if (noOfNewSuborders === 0) {
                      dispatch(notify(false));
                    }
                  }
                } else if (data.type === 'take-away') {
                  // take-away
                  // orders.takeAway.all.push(data); // all
                  // orders.takeAway[data.status].push(data);
                  if (data.status === 'booked') {
                    orders.takeAway.newItems += 1;
                  } // no suborders in takeaway
                }
              }
            });

            // console.log('orders: ', orders);
            updateCachedData((draft) => orders);
          });
        } catch (error) {
          console.log('error in users!', error);
          throw new Error('Something went wrong with users.');
        }
        await cacheEntryRemoved;
        unsubscribe();
      },
    }),
    getOrderHistory: build.query({
      queryFn: async ({ startDate, endDate }) => {
        // console.log('started: ', startDate, endDate);
        const orderHistoryQuery = query(
          collection(db, 'orders'),
          where('orderedTimestamp', '>=', startDate),
          where('orderedTimestamp', '<=', endDate),
          orderBy('orderedTimestamp', 'desc')
        );
        const querySnapshot = await getDocs(orderHistoryQuery);
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        return { data: arr };
      },
    }),
  }),
});

export const { useGetCurrentOrdersQuery, useLazyGetOrderHistoryQuery } = orderSlice;
