import * as React from 'react';
import Box from '@mui/material/Box';
import { Divider, Stack } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { db } from '../../../Firebase/config';
import CurrentOrdersSubTable from '../table/CurrentOrdersSubTable';
import OrdersTable from '../table/OrdersTable';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};

export default function ListModal({ open, handleOpen, handleClose, orderId }) {
  // console.log(('orderlist', orderId));
  const [orderedItems, setOrderedItems] = React.useState(null);
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  React.useEffect(() => {
    const fetchOrder = async () => {
      const docRef = doc(db, 'orders', orderId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        // console.log('Document data:', docSnap.data());
        const data = { ...docSnap.data(), id: docSnap.id };
        setOrderedItems(data);
      } else {
        // docSnap.data() will be undefined in this case
        console.log('No such document!');
      }
    };
    if (orderId) {
      fetchOrder();
    }
  }, [orderId]);

  return (
    <div>
      {/* <ReceiptLongIcon onClick={handleOpen} color="#000" sx={{ cursor: 'pointer' }} /> */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {orderedItems ? `${orderedItems.tableNo}` : 'No orders yet'}
          </Typography>
          <Divider />
          {orderedItems && (
            <>
              <OrdersTable order={orderedItems} setOrderedItems={setOrderedItems} />

              {/* <CurrentOrdersSubTable order={orderedItems} /> */}
            </>
          )}
          {/* {orderedItems?.orderedItems?.map((order, orderIndex) => (
            <div key={orderIndex}>
              {order.items.map((item, itemIndex) => (
                <Stack key={itemIndex} direction="row" justifyContent="space-between" alignItems="center">
                  <Typography sx={{ mt: 2, width: '100%' }}>{item.name}</Typography>
                  <Typography sx={{ mt: 2 }}>{item.quantity}</Typography>
                  <Divider />
                </Stack>
              ))}
            </div>
          ))} */}
          {/* <CurrentOrdersSubTable /> */}
        </Box>
      </Modal>
    </div>
  );
}
