import { useState } from 'react';
import { doc, deleteDoc } from 'firebase/firestore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ItemModal from '../ItemModal/ItemModal';
import { db } from '../../../Firebase/config';

export default function BasicMenu({ items }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  // console.log('itemsId', items.id);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEdit = () => {
    setOpenModal(true);
    setAnchorEl(null);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const handleDelete = async () => {
    const userConfirmed = window.confirm('Do You want to delete the Item');
    if (userConfirmed) {
      try {
        await deleteDoc(doc(db, 'items', items.id));
        alert('Item Deleted');
      } catch (err) {
        console.log('Delete error:', err);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div>
        <MoreVertIcon onClick={handleClick} sx={{ cursor: 'pointer' }} />
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
      {openModal && <ItemModal open={openModal} onClose={handleModalClose} data={items} selectedType="selected" />}
    </div>
  );
}
