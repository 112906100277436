import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Box } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import CurrentOrdersTabs from '../reusable/modal/Current_Orders/CurrentOrdersTabs';
import ItemCategorySelect from '../reusable/modal/CategorySelect/ItemCategorySelect';
import CurrentOverallTabs from '../reusable/modal/Current_Orders/CurrentOverallTabs';

// ----------------------------------------------------------------------

export default function CurrentOrders() {
  const theme = useTheme();

  const alb = {
    color: '#b68e1d',
  };
  const nameandstock = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
  };
  const timeStamp = Date.now();
  // console.log('new Timestamp', timeStamp);
  return (
    <>
      <Helmet>
        <title> Current Orders | Albarakah Fusion </title>
      </Helmet>

      <Container maxWidth="xl">
        {/* <Box sx={nameandstock}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Hi, Welcome back <span style={alb}> albarakah</span>
          </Typography>
          <ItemCategorySelect  title="Out of Stock" label="Out of Stock" />
        </Box> */}

        {/* <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Current Orders" total={714000} icon={'ant-design:android-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Booked Orders" total={1352831} color="info" icon={'ant-design:apple-filled'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Preparing Orders"
              total={1723315}
              color="warning"
              icon={'ant-design:windows-filled'}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Completed Orders" total={234} color="error" icon={'ant-design:bug-filled'} />
          </Grid>
        </Grid> */}
        <CurrentOverallTabs />
      </Container>
    </>
  );
}
