import { memo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { IconButton, TableCell, Tooltip } from '@mui/material';

const HoverTableCell = ({ item, val, i, idx, handleSubOrderStatus }) => {
  // console.log('item: ', val);
  const [hoveredIndex, setHoveredIndex] = useState({
    row: -1,
    col: -1,
  });

  return (
    <TableCell
      align="right"
      sx={{ border: '1px solid black', borderCollapse: 'collapse' }}
      onMouseEnter={() => setHoveredIndex({ row: i, col: idx })}
      onMouseLeave={() => setHoveredIndex({ row: -1, col: -1 })}
    >
      {val && val.name ? val.name : ''}
      {item.status === 'completed' || item.status === 'cancelled'
        ? null
        : hoveredIndex.row === i &&
          hoveredIndex.col === idx && (
            <>
              {/* Transparent button to reduce quantity */}
              {val.quantity > 1 && (
                <IconButton onClick={() => handleSubOrderStatus(item, 'item-quantity', val)}>
                  <Tooltip title="Reduce 1 quantity">
                    <RemoveCircleOutlineIcon />
                  </Tooltip>
                </IconButton>
              )}

              {/* Transparent button to remove item */}
              <IconButton onClick={() => handleSubOrderStatus(item, 'item', val)}>
                <Tooltip title="Remove this item">
                  <DeleteIcon />
                </Tooltip>
              </IconButton>
            </>
          )}
    </TableCell>
  );
};

export default memo(HoverTableCell);
