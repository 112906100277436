import { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { Tooltip } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockCloseIcon from '@mui/icons-material/LockClockOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SwapHorizOutlinedIcon from '@mui/icons-material/SwapHorizOutlined';
import { db } from '../../../Firebase/config';
import QrModal from '../qrcode/QrModal';
import SwapModal from './SwapModal';
import { useLazyGetTableByIdQuery } from '../../../api/tableSlice';
import { errorNotification, successNotification } from '../../../utils/notifications';

const IconGroup = ({ data, item, setTableList }) => {
  const [getTableById] = useLazyGetTableByIdQuery();
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [openSwap, setOpenSwap] = useState(false);

  const handleOpen = () => setOpenSwap(true);
  const handleClose = () => setOpenSwap(false);

  const handleOpenLock = async (id) => {
    // const tableRef = doc(db, 'tables', id);
    // await updateDoc(tableRef, { status: 'free', isOccupied: false });

    const tableRef = doc(db, 'tables', id);
    await updateDoc(tableRef, {
      isLock: false,
      isOccupied: false,
      status: 'free',
    });

    const listTable = data.map(
      (item) => (item.id === id ? { ...item, status: 'free', occupied: false } : item)
      // item.id === id ? { ...item, status: 'free', occupied: false, userScanned: '' } : item
    );
    setIsUnlocked(true);
    setTableList(listTable);
  };

  const handleClearOrder = async (id) => {
    // console.log('id: ', id);
    let currentItem;

    const listTable = data.map((item) => {
      if (item.id === id) {
        console.log('item: ', item);
        currentItem = {
          ...item,
        };
        return { ...item, status: 'free', occupied: false };
      }
      return item;
    });

    // console.log('ci: ', listTable);

    const userRef = doc(db, 'users', currentItem.userScanned);
    await updateDoc(userRef, { currentOrderId: '' });

    // const tableRef = doc(db, 'tables', id);
    // await updateDoc(tableRef, { status: 'free', isOccupied: false, currentOrderId: '' });

    setTableList(listTable);
    setIsUnlocked(false);
  };

  const handleSwapTableOrders = async (tableItem, destinationTable) => {
    // console.log('tableItem: ', tableItem, destinationTable);

    try {
      if (tableItem.id === destinationTable) {
        alert(`You can't transfer to same table: ${destinationTable}`);
      } else {
        const res = await getTableById(destinationTable).unwrap();
        // console.log('res: ', res);

        // now 4 write will happen
        // 1. chnage the 2 docs currentOrderId - 2 write
        // 2. change the tableNo in those two current order id - 2 write
        const promises = [];

        let type = 'transfer';

        // transfer to that table
        const table1Ref = doc(db, 'tables', destinationTable);
        promises.push(
          updateDoc(table1Ref, {
            currentOrderId: tableItem?.currentOrderId,
            isLock: tableItem?.isLock,
            isOccupied: tableItem?.isOccupied,
            status: tableItem?.status,
          })
        );

        const table2Ref = doc(db, 'tables', tableItem.id);
        promises.push(
          updateDoc(table2Ref, {
            currentOrderId: res.currentOrderId,
            isLock: res?.isLock ?? false,
            isOccupied: res?.isOccupied,
            status: res?.status,
          })
        );

        if (res.currentOrderId) {
          type = 'swap';

          const order1Ref = doc(db, 'orders', res.currentOrderId);
          promises.push(
            updateDoc(order1Ref, {
              tableNo: tableItem.id,
            })
          );
        }

        const order2Ref = doc(db, 'orders', tableItem.currentOrderId);
        promises.push(
          updateDoc(order2Ref, {
            tableNo: destinationTable,
          })
        );

        const result = await Promise.all(promises);

        // console.log('resultt: ', result);

        successNotification(
          type === 'transfer' ? 'Order details successfully transferred' : 'Order details successfully swapped'
        );

        handleClose();
      }
    } catch (e) {
      console.log('e: ', e);
      errorNotification(e.message || 'Some error, please try after sometime');
    }
  };

  return item?.occupied ? (
    <>
      <QrModal qrUrl={item.qrUrl} />
      {openSwap && (
        <SwapModal
          item={item}
          open={openSwap}
          handleOpen={handleOpen}
          handleClose={handleClose}
          handleSwapTableOrders={handleSwapTableOrders}
        />
      )}
      {data.status === 'free' ? (
        <Tooltip title="Table is unlocked already and free to take orders" placement="top">
          <LockOpenIcon
            sx={{ fontSize: '24px', cursor: 'pointer' }}
            // onClick={() =>item.curOrderId && handleLocked(item.id) }
          />
        </Tooltip>
      ) : (
        <Tooltip title="Only unlock the table & don't clear order" placement="top">
          <LockCloseIcon sx={{ fontSize: '24px', cursor: 'pointer' }} onClick={() => handleOpenLock(item.id)} />
        </Tooltip>
      )}
      {isUnlocked && (
        <Tooltip title={'clear the order'} placement="top">
          <DeleteForeverIcon sx={{ fontSize: '24px', cursor: 'pointer' }} onClick={() => handleClearOrder(item.id)} />
        </Tooltip>
      )}
      <Tooltip title={'Swap the order to different table'} placement="top">
        <SwapHorizOutlinedIcon sx={{ fontSize: '24px', cursor: 'pointer' }} onClick={() => handleOpen()} />
      </Tooltip>
    </>
  ) : (
    <>
      <QrModal qrUrl={item.qrUrl} />

      <Tooltip title="Table is unlocked already and free to take orders" placement="top">
        <LockOpenIcon
          sx={{ fontSize: '24px', cursor: 'pointer' }}
          // onClick={() =>item.curOrderId && handleLocked(item.id) }
        />
      </Tooltip>

      {isUnlocked && (
        <Tooltip title="clear the order & unlock the table" placement="top">
          <DeleteForeverIcon sx={{ fontSize: '24px', cursor: 'pointer' }} onClick={() => handleClearOrder(item.id)} />
        </Tooltip>
      )}
    </>
  );
};

export default IconGroup;
