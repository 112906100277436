import * as React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { doc, updateDoc } from 'firebase/firestore';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import { isValidEmail, isValidName, isValidNumber } from '../../utils/validator';
import { errorNotification } from '../../utils/notifications';
import StatusSelect from './CategorySelect/StatusSelect';
import { db } from '../../Firebase/config';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const paymentInput = ['Cash', 'UPI(Gpay,Phonepe)', 'Card', 'Net Banking'];

const statusInput = ['Paid', 'Not Paid'];

export default function StatusModal({ open, docId, closeModal, order, setShowModalTakeAway, setOrderedItems }) {
  // console.log('order: ', order);
  const [payment, setPayment] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState(order?.userDetails?.phone);
  const [error, setError] = useState(true);
  const { pathname } = useLocation();

  const handleClose = () => {
    closeModal();
  };

  const handlePayment = (pay) => {
    // console.log('data', pay);
    setPayment(pay);
  };

  const handleValue = (val) => {
    // console.log('val', val);
    setPaymentStatus(val);
  };

  const handleSave = async () => {
    if (payment.trim().length === 0) {
      alert('select Payment Method');
      return;
    }
    if (paymentStatus.trim().length === 0) {
      alert('select whether Paid or Not-Paid');
      return;
    }

    if (email && !isValidEmail(email)) {
      errorNotification('Enter valid email');
      return;
    }

    // console.log('phone: ', phone);
    // if (phone !== '' && !isValidNumber(phone)) {
    //   errorNotification('Enter valid phone num');
    //   return;
    // }

    // if (name !== '') {
    //   errorNotification('Enter valid name');
    //   return;
    // }

    try {
      const completeRef = doc(db, 'orders', docId);
      if (order.type === 'take-away') {
        await updateDoc(completeRef, {
          status: 'preparing',
          paymentMethod: payment,
          paymentStatus,
          userDetails: { name, phone, email },
        });
      } else {
        await updateDoc(completeRef, {
          status: 'completed',
          paymentMethod: payment,
          paymentStatus,
          userDetails: { name, phone, email },
        });
        await updateDoc(doc(db, 'users', order?.userDetails?.phone), {
          currentOrderId: '',
        });
        // console.log('path: ', pathname);
        if (pathname.includes('table-management')) {
          setOrderedItems({
            ...order,
            status: 'completed',
            paymentMethod: payment,
            paymentStatus,
            userDetails: { name, phone, email },
          });
        }
        // again in end user - it requested again -> which is in loop
        const tableRef = doc(db, 'tables', `${order?.tableNo}`);
        await updateDoc(tableRef, {
          status: 'free',
          isOccupied: false,
          currentOrderId: '',
        });
      }

      setShowModalTakeAway(true);
      if (error) {
        handleClose();
        console.log('error', error);
      }
    } catch (e) {
      console.log('err: ', e);
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <StatusSelect type="payment" inputValue={paymentInput} onSelect={handlePayment} />
          </div>
          <div>
            <StatusSelect type="status" inputValue={statusInput} onChoose={handleValue} />
          </div>
          <div style={{ display: 'grid', gap: '15px' }}>
            <TextField label="Phone" value={phone} disabled />

            <TextField label="Email(optional)" onChange={(e) => setEmail(e.target.value)} value={email} />

            <TextField label="Name(optional)" onChange={(e) => setName(e.target.value)} value={name} />
            {/* <TextField label="Phone" onChange={(e) => setPhone(e.target.value)} value={phone} /> */}
          </div>
          <br />
          <div className="btnContainer">
            <Button variant="contained" className="saveBtn" onClick={handleSave}>
              Save
            </Button>
            <Button variant="outlined" onClick={handleClose} className="cancelBtn">
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
