import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Grid } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';
import { AppWidgetSummary } from '../../../sections/@dashboard/app';
import StockPopover from './StockPopover';
import { useGetCurrentOrdersQuery } from '../../../api/orderSlice';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(1, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const { data, isError, isFetching, isLoading } = useGetCurrentOrdersQuery();
  // console.log('currentOrder: ', data, isError, isFetching, isLoading);

  const grid = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        {/* <Searchbar /> */}
        <Grid container spacing={3} sx={grid}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              sx={{ padding: '10px' }}
              title="Today Booked"
              color="info"
              icon={'ant-design:apple-filled'}
              Dineintitle="Dine-In"
              Dineintotal={data?.dineIn?.booked?.length || 0}
              Takeaway="Take-Away"
              Takeawaytotal={data?.takeAway?.booked?.length || 0}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              sx={{ padding: '10px' }}
              title="Today Preparing"
              // total={data?.dineIn?.preparing?.length || 0}
              color="warning"
              icon={'ant-design:windows-filled'}
              Dineintitle="Dine-In"
              Dineintotal={data?.dineIn?.preparing?.length || 0}
              Takeaway="Take-Away"
              Takeawaytotal={data?.takeAway?.preparing?.length || 0}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              sx={{ padding: '10px' }}
              title="Today Completed "
              // total={data?.dineIn?.completed?.length || 0}
              color="error"
              icon={'ant-design:bug-filled'}
              Dineintitle="Dine-In"
              Dineintotal={data?.dineIn?.completed?.length || 0}
              Takeaway="Take-Away"
              Takeawaytotal={data?.takeAway?.completed?.length || 0}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              sx={{ padding: '10px' }}
              title="Today Total"
              // total={
              //   data?.dineIn?.booked?.length + data?.dineIn?.preparing?.length + data?.dineIn?.completed?.length || 0
              // }
              icon={'ant-design:android-filled'}
              Dineintitle="Dine-In"
              Dineintotal={data?.dineIn?.all?.length || 0}
              Takeaway="Take-Away"
              Takeawaytotal={data?.takeAway?.all?.length || 0}
            />
          </Grid>
        </Grid>
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          {/* <LanguagePopover /> */}
          <StockPopover />
          <NotificationsPopover />
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
