import { collection, doc, getDoc, onSnapshot, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../services/firebase';
import { selectUserDetail } from '../store/userSlice';

const { apiSlice } = require('./apiSlice');

const collectionName = 'tables'; // name of the collection

const tableSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTableRequests: build.query({
      queryFn: async () => ({ data: null }),
      onCacheEntryAdded: async (_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch, getState }) => {
        // _ as 1st param, it requried to get 2nd param, but not using, so kept as _
        let unsubscribe = () => {};
        try {
          await cacheDataLoaded;

          const userDetail = selectUserDetail(getState());

          const currentOrdersRef = query(
            collection(db, collectionName),
            where('branch', '==', userDetail.branch),
            where('status', '==', 'requested')
          );

          unsubscribe = onSnapshot(currentOrdersRef, (snapshot) => {
            // console.log('snapshot', snapshot.size);
            const tablesRequested = [];

            snapshot.docs.forEach((doc) => {
              const data = {
                id: doc.id,
                ...doc.data(),
              };
              tablesRequested.push(data);
            });

            tablesRequested.sort((a, b) => a.name - b.name);

            updateCachedData((draft) => tablesRequested);
          });
        } catch (error) {
          console.log('error in tables subscription!', error);
          throw new Error('Something went wrong with tables.');
        }
        await cacheEntryRemoved;
        unsubscribe();
      },
    }),
    updateTableRequestStatus: build.mutation({
      queryFn: async ({ id, status }) => {
        try {
          console.log('tableNo: ', id, status);
          const docId = id;
          const tableRef = doc(db, collectionName, docId);

          await updateDoc(tableRef, {
            status,
          });

          return {
            data: 'ok',
          };
        } catch (e) {
          console.log('error in tables update!', e);
          return {
            error: e,
          };
        }
      },
    }),
    getAllTables: build.query({
      queryFn: async () => ({ data: null }),
      onCacheEntryAdded: async (_, { updateCachedData, cacheDataLoaded, cacheEntryRemoved, dispatch, getState }) => {
        // _ as 1st param, it requried to get 2nd param, but not using, so kept as _
        let unsubscribe = () => {};
        try {
          await cacheDataLoaded;

          const userDetail = selectUserDetail(getState());

          const currentOrdersRef = query(collection(db, collectionName), where('branch', '==', userDetail.branch));

          unsubscribe = onSnapshot(currentOrdersRef, (snapshot) => {
            const tables = [];

            snapshot.docs.forEach((doc) => {
              const docData = doc.data();

              const data = {
                id: doc.id,
                ...doc.data(),
                capacity: docData.capacity,
                curOrderId: docData.currentOrderId,
                occupied: docData.isOccupied,
                tableName: docData.name,
                status: docData.status,
                tableNum: docData.tableNo,
                qrUrl: docData.url,
                userScanned: docData.userScanned,
              };
              tables.push(data);
            });

            // console.log('table before sort: ', tables);

            tables.sort((a, b) => {
              const numA = parseInt(a.name.split('-').pop(), 10);
              const numB = parseInt(b.name.split('-').pop(), 10);
              return numA - numB;
            });

            // console.log('table after sort: ', tables);

            updateCachedData((draft) => tables);
          });
        } catch (error) {
          console.log('error in tables subscription!', error);
          throw new Error('Something went wrong with tables.');
        }
        await cacheEntryRemoved;
        unsubscribe();
      },
    }),
    getTableById: build.query({
      queryFn: async (docId) => {
        try {
          const docRef = doc(db, collectionName, docId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            // console.log("Document data:", docSnap.data());
            return {
              data: docSnap.data(),
            };
          }
          // docSnap.data() will be undefined in this case
          return {
            // only returns data, error, meta
            error: {
              name: 'invalid_id',
              message: `No such document - path: ${collection}/${docId}`,
              data: '',
            },
          };
        } catch (e) {
          return {
            error: {
              name: 'getItemById',
              message: e?.message || 'error',
              data: e,
            },
          };
        }
      },
    }),
  }),
});

export const {
  useGetTableRequestsQuery,
  useUpdateTableRequestStatusMutation,
  useGetAllTablesQuery,
  useLazyGetTableByIdQuery,
} = tableSlice;
