import React, { useState, useRef } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import classes from './Profile.module.css';

const ProfilePic = ({ getImage, getFile, selectedUrl }) => {
  const [profilePic, setProfilePic] = useState(selectedUrl || null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileName = e.target.files[0].name;
    const reader = new FileReader();
    getFile(file);
    getImage(fileName);
    // console.log('image', e.target.files[0].name);

    reader.onloadend = () => {
      setProfilePic(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageKeyPress = (e) => {
    if (e.key === 'Enter') {
      fileInputRef.current.click();
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={handleImageClick}
      onKeyPress={handleImageKeyPress}
      style={{ outline: 'none', cursor: 'pointer' }}
    >
      {profilePic ? (
        <img src={profilePic} alt="Profile" className={classes.image1} />
      ) : (
        <div className={classes.imagecover}>
          {/* <AccountCircleIcon sx={{ margin: 'auto', width: '100%', fontSize: '18rem' }} /> */}
          <img src="/images/thumbnail-default@2x.png" alt="" className={classes.changingimage} />
        </div>
      )}
      <input type="file" accept="image/*" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
    </div>
  );
};

export default ProfilePic;
